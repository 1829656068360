import React, { createContext, ReactNode, useEffect, useState } from 'react';
import * as apiService from "../backend/apiService";

interface RecommendationsContextType {
    hasRecs: boolean;
    setHasRecs: (hasRecs: boolean) => void;
    checkRecommendations: () => Promise<void>;
}

const RecommendationsContext = createContext<RecommendationsContextType | undefined>(undefined);

export const RecommendationsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [hasRecs, setHasRecs] = useState<boolean>(false);

    const checkRecommendations = async () => {
        try {
            await Promise.all([
                apiService.fetchUserCities(),
                apiService.fetchUserSeeds()
            ]);
            setHasRecs(true);
        } catch (error) {
            console.error("Error checking recommendations:", error);
            setHasRecs(false);
        }
    };

    useEffect(() => {
        checkRecommendations().then();
    }, []);

    return (
        <RecommendationsContext.Provider value={{ hasRecs, setHasRecs, checkRecommendations }}>
            {children}
        </RecommendationsContext.Provider>
    );
};

export const useRecommendations = (): RecommendationsContextType => {
    const context = React.useContext(RecommendationsContext);
    if (!context) throw new Error("useRecommendations must be used within a RecommendationsProvider");
    return context;
};

