import React, {useState} from "react";
import styles from "../style/pages/Settings.module.scss";
import globalColors from "../style/globalColors";
import ActivityIndicator from "./ActivityIndicator";
import {MergeableAccount} from "../backend/loginApi";
import * as stringFormatting from "../utils/StringFormatting";

type Props = {
    onClose: () => void;
    mergeableAccount: MergeableAccount;
    onSubmit: (id: string | undefined, authToken: string | undefined, changeEmail: boolean) => void;
    fromSpotify: boolean;
    email: string | undefined;
}

const MergeAccountsModal: React.FC<Props> = ({
    onClose,
    mergeableAccount,
    onSubmit,
    fromSpotify,
    email
}) => {

    const [mergeButtonActive, setMergeButtonActive] = useState<boolean>(false);

    return (
        <div
            style={{
                position: "fixed", // Fixed position for modal
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000, // Adjust the z-index as needed
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
            }}
        >
            <div
                style={{
                    width: "40%", // You can adjust the width as needed
                    backgroundColor: globalColors.dark,
                    padding: "20px", // Add padding for content
                    borderRadius: "10px",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)", // Optional shadow
                    color: globalColors.white,
                    justifyItems: "center",
                    alignItems: "center"
                }}

            >
                <div>
                    <h3
                        style={{
                            height: 20
                        }}
                    >
                        Account Exists Already.
                    </h3>
                    <div className={styles['main-section']}>
                        <div className={styles['section-header']}>
                            <h4>Existing Account:</h4>
                        </div>

                        <h6 className="grey12">Name: {mergeableAccount.fromUserName}</h6>
                        <h6 className="grey12">Email: {mergeableAccount.fromUserEmail ? mergeableAccount.fromUserEmail : "No Email Given"} </h6>
                        <h6 className="grey12">Member Since: {stringFormatting.formatMonthDayYear(mergeableAccount.fromUserCreatedAt)}</h6>
                        <h6 className="grey12">Spotify Username: {mergeableAccount.fromUserSpotifyUsername != null ? mergeableAccount.fromUserSpotifyUsername : 'Not Connected'}</h6>

                    </div>
                    <div className={styles['main-section']}>
                        <div className={styles['section-header']}>
                            <h4>New Account:</h4>
                        </div>

                        <h6 className="grey12">Name: {mergeableAccount.mergedAccountPreview.name}</h6>
                        <h6 className="grey12">Email: {mergeableAccount.mergedAccountPreview.email ? mergeableAccount.mergedAccountPreview.email : "No Email Given"} </h6>
                        <h6 className="grey12">Member Since: {stringFormatting.formatMonthDayYear(mergeableAccount.fromUserCreatedAt)}</h6>
                        <h6 className="grey12">Spotify Username: {mergeableAccount.mergedAccountPreview.spotifyName != null ? mergeableAccount.mergedAccountPreview.spotifyName : "Not Connected"}</h6>

                    </div>
                    <div className={styles['warning-message']}>
                        <h5>WARNING: This action will delete your old account.</h5>
                    </div>
                    <div className={fromSpotify ? styles['buttons-spotify'] : styles['buttons']}>
                        <h6 onClick={() => {
                            onClose()
                        }
                        }>Cancel</h6>
                        <h6 onClick={() => onSubmit(mergeableAccount.id, mergeableAccount.authToken, email != undefined)}>
                            {!mergeButtonActive ? `Merge Accounts` : (
                                <div style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                }}>
                                    <ActivityIndicator color="white" width={"20px"} height={"20px"}/>
                                </div>
                            )}
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MergeAccountsModal;
