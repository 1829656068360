import React from "react";
import { EventResponse, FavoriteTypes } from "../backend/apiService";
import styles from "../style/pages/DetailPages.module.scss";
import * as stringFormatting from "../utils/StringFormatting";
import { useNavigate } from "react-router-dom";
import BookmarkButton from "./BookmarkButton";

type Props = {
    event: EventResponse;
    displayCity?: boolean;
};

const noImage = 'https://localify-cdn.s3.amazonaws.com/assets/web_assets/NoImage.jpeg';

const EventListCard: React.FC<Props> = ({ event, displayCity }) => {
    const formattedDate = stringFormatting.formatDateCard(event.startTime);
    const navigate = useNavigate();

    return (
        <div className={styles["event-list-card"]}>
            <div className={styles["event-image-container"]}>
                <img src={event.topArtists[0]?.image || noImage} alt={event.name} className={styles["event-image"]} />
                <div className={styles["date-overlay"]}>
                    <h6 className={styles["date-day"]}>{formattedDate.day}</h6>
                    <h5 className={styles["date-month-day"]}>{formattedDate.month} {formattedDate.dayOfMonth}</h5>
                    <h6 className={styles["date-time"]}>{formattedDate.time}</h6>
                </div>
            </div>
            <div className={styles["info-button-container"]}>
                <div className={styles["event-info"]}>
                    {displayCity ?
                        <>
                            <h4>
                                {event.venue.city.name + ", " + (event.venue.city.zoneCode || event.venue.city.countryCode)}
                            </h4>
                            <h6
                                className={styles["venue-name"]}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(`/venue/${event.venue.id}/${stringFormatting.toSlug(event.venue.name)}`)
                                }}
                            >{event.venue.name}</h6>
                        </> :
                        <h4>{event.name}</h4>
                    }

                    <div className={styles["performing-artists"]}>
                        {event.topArtists.slice(0, 3).map((artist, index) => (
                            <div key={index} className={styles["performing-artist-container"]}>
                                <h6
                                    className={styles["performing-artist"]}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(`/artist/${artist.id}/${stringFormatting.toSlug(artist.name)}`);
                                    }}
                                >{artist.name}</h6>
                                {index !== event.topArtists.length - 1 && <h6>,&nbsp;</h6>}
                            </div>
                        ))}
                        {event.topArtists.length > 3 && <h6>and more...</h6>}
                    </div>
                </div>
                <div className={styles["card-buttons"]}>
                    <BookmarkButton
                        initialIsFavorite={event.isFavorite}
                        type={FavoriteTypes.events}
                        id={event.id}
                        handleError={() => console.log("Failed to error")} //TODO: Make real error
                        isForCard={true}
                    />
                    <div />
                    {event.ticketUrl &&
                        <h6
                            className={styles["get-tickets-button"]}
                            onClick={(e) => {
                                e.stopPropagation();
                                window.open(event.ticketUrl, "_blank");
                            }}
                        >Get Tickets</h6>}
                </div>
            </div>
        </div>
    );
};

export default EventListCard;
