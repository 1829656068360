import React, {CSSProperties} from 'react';
import globalColors from "../style/globalColors";
import {useNavigate} from "react-router-dom";
import PageHeader, {HeaderTypes} from "../components/PageHeader";

const NotFound: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div style={styles.container}>
            <PageHeader type={HeaderTypes.DEFAULT} />
            <h2 style={styles.header}>Page Not Found</h2>
            <h6 style={styles.header}>Please check the website link.</h6>
            <h4
                style={styles.subheader}
                onClick={() => navigate('/')}
            >Back to Home</h4>
        </div>
    );
}

const styles: { [key: string]: CSSProperties } = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
    },
    header: {
        textAlign: 'center',
        margin: '0px'
    },
    subheader: {
        textAlign: 'center',
        margin: '30px',
        backgroundColor: globalColors.primary,
        padding: "8px 15px",
        borderRadius: "25px",
        cursor: "pointer"
    }
}

export default NotFound;
