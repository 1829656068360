import React, { useState } from 'react';
import PageHeader, {HeaderTypes} from "../components/PageHeader";
import styles from "../style/pages/FeedbackForm.module.scss";
import * as apiService from "../backend/apiService";
import FeedbackFormThankYouModal from "../components/FeedbackThankYouModal"

interface FeedbackFormState {
    feedback: string;
    email: string;
}

const FeedbackForm: React.FC = () => {
    const [formState, setFormState] = useState<FeedbackFormState>({ feedback: '', email: '' });
    const [showThankYou, setShowThankYou] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormState(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = () => {
        //e.preventDefault(); // Prevent the form from actually submitting to a server
        setFormState({feedback: '', email: ''})
        apiService.submitFeedback(formState.feedback, formState.email).then();
        setShowThankYou(true);
    };

    const handleCloseModal = () => {
        setShowThankYou(false);
    }

    return (
        <div className={"App-Body"}>
            <div
                className={styles["container"]}
                onKeyDown={(e) => {
                    if (e.key === "Escape")
                        handleCloseModal();
                }}
            >
                <div className={styles["box"]}>
                    <h2 className={styles["title"]}>Localify Feedback Form</h2>
                    <div style={{ margin: '10px 0' }}>
                        <label htmlFor="feedback" style={{ display: 'block', marginBottom: '5px' }}>
                            Let us know what you think!
                        </label>
                        <textarea
                            className={styles["feedback-input"]}
                            id="feedback"
                            name="feedback"
                            value={formState.feedback}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div style={{ margin: '10px 0' }}>
                        <label htmlFor="email" style={{ display: 'block', marginBottom: '5px' }}>
                            Your Email (optional)
                        </label>
                        <input
                            placeholder={"Email Address"}
                            className={styles["email-input"]}
                            type="email"
                            id="email"
                            name="email"
                            value={formState.email}
                            onChange={handleInputChange}
                        />
                    </div>
                    <button
                        onClick={handleSubmit}
                        className={styles["submit-button"]}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FeedbackForm;
