import React, {useState} from "react";
import * as apiService from "../backend/apiService";
import {FavoriteTypes} from "../backend/apiService";
import styles from "../style/components/RecommendationCard.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import {Favorite, FavoriteBorder} from "@mui/icons-material";

type Props = {
    initialIsFavorite: boolean;
    type: FavoriteTypes;
    id: string;
    handleError: () => void;
    isForCard?: boolean;
}

const BookmarkButton: React.FC<Props> = ({initialIsFavorite, type, id, handleError, isForCard}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [isFavorite, setIsFavorite] = useState<boolean>(initialIsFavorite);

    const handleBookmarkClicked = async (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (!loading) {
            setLoading(true);
            try {
                if (isFavorite) {
                    await apiService.removeFavorite(id, type);
                    setIsFavorite(false);
                } else {
                    await apiService.addFavorite(id, type);
                    setIsFavorite(true);
                }
            } catch {
                handleError();
            } finally {
                setLoading(false);
            }
        }
    }

    return (
        <div
            className={`${styles["bookmark-container"]} ${isForCard && styles["card-bookmark"]}`}
            onClick={handleBookmarkClicked}
        >
            {loading ? (
                <CircularProgress className={'primary'} />
            ) : (
                type === FavoriteTypes.artists ? (
                    isFavorite ? (
                        <Favorite className={styles['bookmark-icon']} fontSize={"small"}/>
                    ) : (
                        <FavoriteBorder className={styles['bookmark-icon']} fontSize={"small"} />
                    )
                ) : (
                    isFavorite ? (
                        <BookmarkIcon className={styles['bookmark-icon']} fontSize={"small"} />
                    ) : (
                        <BookmarkBorderIcon className={styles['bookmark-icon']} fontSize={"small"} />
                    )
                )
            )}
        </div>
    )
};

export default BookmarkButton;
