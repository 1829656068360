import React, {useEffect, useState} from "react";
import * as loginApi from "../backend/loginApi";
import * as apiService from "../backend/apiService";
import {FavoriteTypes} from "../backend/apiService";
import * as Store from "../backend/storage";

const backendEndpoints = [
    'Guest Login',
    'Refresh Token',
    'Fetch User Details',
    'Patch User Details',
    'Delete User Account',
    'Fetch User Nearest Cities',
    'Fetch User Cities',
    'Put User City',
    'Patch User Cities',
    'Delete User City',
    'Fetch User Seeds',
    'Put User Seeds',
    'Add Artist To User Seeds',
    'Delete Artist From User Seeds',
    'Fetch User Search History',
    'Delete User Search History',
    'Fetch Artist',
    'Fetch Events For Artist',
    'Fetch Cities For Artist',
    'Add City For Artist',
    'Fetch Search Artists',
    'Fetch City Details',
    'Fetch Artists For Cities',
    'Fetch Events For Cities',
    'Fetch Search Cities',
    'Fetch Curated Genres',
    'Fetch Top Genres',
    'Fetch Artists For Genres',
    'Fetch Artist Recommendations',
    'Fetch Event Recommendations',
    'Fetch Search',
    'Add Venue Favorite',
    'Remove Venue Favorite',
    'Add Event Favorite',
    'Remove Event Favorite',
    'Add Artist Favorite',
    'Remove Artist Favorite',
    'Fetch Event'
]


const BackendTesting: React.FC = () => {
    const [testsPassed, setTestsPassed] = useState<string[]>([]);
    const [testsFailed, setTestsFailed] = useState<string[]>([]);

    useEffect(() => {
        runTests().then()
    }, [])

    const runTests = async () => {
        loginApi.createGuestAccount().then(async token => {
            setTestsPassed(prevState => [...prevState, "Guest Login"]);
            await Store.storeTokenData(token)

            loginApi.refreshToken(token.refreshToken)
                .then(() => setTestsPassed(prevState => [...prevState, "Refresh Token"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Refresh Token"]))

            apiService.fetchUserNearestCities()
                .then(() => setTestsPassed(prevState => [...prevState, "Fetch User Nearest Cities"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Fetch User Nearest Cities"]))

            await apiService.putUserCity('d973c8d3-4bc6-410b-94f1-6e3750798837', 25)
                .then(() => {
                    apiService.putUserCity('f0f43224-fc53-4b5f-88c1-bd2227fbae45', 25)
                        .then(() => setTestsPassed(prevState => [...prevState, "Put User City"]))
                        .catch(() => setTestsFailed(prevState => [...prevState, "Put User City"]))
                })
                .catch(() => setTestsFailed(prevState => [...prevState, "Put User City"]))

            apiService.fetchCuratedGenres()
                .then(() => setTestsPassed(prevState => [...prevState, "Fetch Curated Genres"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Fetch Curated Genres"]))

            apiService.fetchArtistsForGenres(["92fbea7a-e044-4caa-9bd2-7e7a2a080c19", "7be5fbd9-f85c-41bb-ba93-09e653a404f9", "df2557aa-ca5c-4955-adbb-53afce5b16c4"])
                .then(() => setTestsPassed(prevState => [...prevState, "Fetch Artists For Genres"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Fetch Artists For Genres"]))

            apiService.fetchTopGenres()
                .then(() => setTestsPassed(prevState => [...prevState, "Fetch Top Genres"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Fetch Top Genres"]))

            await apiService.putUserSeeds(["8aa91ebb-bc82-420a-8643-ce02e4aae179", "9fb78b90-04c5-4981-9099-a0ed879db89d", "5da90036-6d7f-4123-abae-a99d86775c9e"])
                .then(() => setTestsPassed(prevState => [...prevState, "Put User Seeds"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Put User Seeds"]))

            apiService.addArtistToUserSeeds("0b38ed91-749b-4c38-abda-e0f39ea0baa3")
                .then(() => setTestsPassed(prevState => [...prevState, "Add Artist To User Seeds"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Add Artist To User Seeds"]))

            apiService.fetchUserDetails()
                .then(() => setTestsPassed(prevState => [...prevState, "Fetch User Details"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Fetch User Details"]))

            apiService.patchUserDetails({name: "April", emailOptIn: false})
                .then(() => setTestsPassed(prevState => [...prevState, "Patch User Details"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Patch User Details"]))

            apiService.fetchArtist("0b38ed91-749b-4c38-abda-e0f39ea0baa3")
                .then(() => setTestsPassed(prevState => [...prevState, "Fetch Artist"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Fetch Artist"]))

            apiService.fetchUserCities()
                .then(() => setTestsPassed(prevState => [...prevState, "Fetch User Cities"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Fetch User Cities"]))

            await apiService.patchUserCities('f0f43224-fc53-4b5f-88c1-bd2227fbae45', true)
                .then(() => setTestsPassed(prevState => [...prevState, "Patch User Cities"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Patch User Cities"]))

            apiService.deleteUserCity('d973c8d3-4bc6-410b-94f1-6e3750798837')
                .then(() => setTestsPassed(prevState => [...prevState, "Delete User City"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Delete User City"]))

            apiService.blacklistSeedArtist("8aa91ebb-bc82-420a-8643-ce02e4aae179")
                .then(() => setTestsPassed(prevState => [...prevState, "Delete Artist From User Seeds"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Delete Artist From User Seeds"]))

            apiService.fetchUserSeeds()
                .then(() => setTestsPassed(prevState => [...prevState, "Fetch User Seeds"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Fetch User Seeds"]))

            apiService.fetchArtistRecommendations('f0f43224-fc53-4b5f-88c1-bd2227fbae45')
                .then(() => setTestsPassed(prevState => [...prevState, "Fetch Artist Recommendations"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Fetch Artist Recommendations"]))

            apiService.fetchEventRecommendations('f0f43224-fc53-4b5f-88c1-bd2227fbae45', {})
                .then(() => setTestsPassed(prevState => [...prevState, "Fetch Event Recommendations"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Fetch Event Recommendations"]))

            apiService.fetchCityDetails('f0f43224-fc53-4b5f-88c1-bd2227fbae45')
                .then(() => setTestsPassed(prevState => [...prevState, "Fetch City Details"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Fetch City Details"]))

            apiService.fetchArtistsForCities('f0f43224-fc53-4b5f-88c1-bd2227fbae45')
                .then(() => setTestsPassed(prevState => [...prevState, "Fetch Artists For Cities"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Fetch Artists For Cities"]))

            apiService.fetchCitiesForArtist("2760f4c5-4c7a-4c90-8889-5c17e7b278c8")
                .then(() => setTestsPassed(prevState => [...prevState, "Fetch Cities For Artist"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Fetch Cities For Artist"]))

            apiService.fetchEventsForArtist("1b72e4a5-5d2e-419a-beca-8a32b8e6f32c")
                .then(() => setTestsPassed(prevState => [...prevState, "Fetch Events For Artist"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Fetch Events For Artist"]))

            apiService.fetchEventsForCities('f0f43224-fc53-4b5f-88c1-bd2227fbae45')
                .then(() => setTestsPassed(prevState => [...prevState, "Fetch Events For Cities"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Fetch Events For Cities"]))

            apiService.fetchEvent("3325094e-c473-42d9-978c-0bb13e8779ec")
                .then(() => setTestsPassed(prevState => [...prevState, "Fetch Event"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Fetch Event"]))

            apiService.fetchSearchCities('a')
                .then(() => setTestsPassed(prevState => [...prevState, "Fetch Search Cities"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Fetch Search Cities"]))

            apiService.fetchSearchArtists('a')
                .then(() => setTestsPassed(prevState => [...prevState, "Fetch Search Artists"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Fetch Search Artists"]))

            await apiService.addFavorite("1b72e4a5-5d2e-419a-beca-8a32b8e6f32c", FavoriteTypes.artists)
                .then(() => setTestsPassed(prevState => [...prevState, "Add Artist Favorite"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Add Artist Favorite"]))

            await apiService.addFavorite("9f00d39e-07e9-4aa9-86dd-0eaab8c7a08d", FavoriteTypes.venues)
                .then(() => setTestsPassed(prevState => [...prevState, "Add Venue Favorite"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Add Venue Favorite"]))

            await apiService.addFavorite("61089011-7085-4028-a8d8-dbfce045968f", FavoriteTypes.events)
                .then(() => setTestsPassed(prevState => [...prevState, "Add Event Favorite"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Add Event Favorite"]))

            apiService.removeFavorite("1b72e4a5-5d2e-419a-beca-8a32b8e6f32c", FavoriteTypes.artists)
                .then(() => setTestsPassed(prevState => [...prevState, "Remove Artist Favorite"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Remove Artist Favorite"]))

            apiService.removeFavorite("9f00d39e-07e9-4aa9-86dd-0eaab8c7a08d", FavoriteTypes.venues)
                .then(() => setTestsPassed(prevState => [...prevState, "Remove Venue Favorite"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Remove Venue Favorite"]))

            apiService.removeFavorite("61089011-7085-4028-a8d8-dbfce045968f", FavoriteTypes.events)
                .then(() => setTestsPassed(prevState => [...prevState, "Remove Event Favorite"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Remove Event Favorite"]))

            apiService.deleteUserAccount()
                .then(() => setTestsPassed(prevState => [...prevState, "Delete User Account"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Delete User Account"]))

            await apiService.fetchSearch('a', false)
                .then(() => setTestsPassed(prevState => [...prevState, "Fetch Search"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Fetch Search"]))

            await apiService.fetchUserSearchHistory()
                .then(() => setTestsPassed(prevState => [...prevState, "Fetch User Search History"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Fetch User Search History"]))

            apiService.deleteUserSearchHistory()
                .then(() => setTestsPassed(prevState => [...prevState, "Delete User Search History"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Delete User Search History"]))

            //Guests can't contribute to cities for artists
            /*apiService.addCityForArtist("2760f4c5-4c7a-4c90-8889-5c17e7b278c8", "f0f43224-fc53-4b5f-88c1-bd2227fbae45")
                .then(() => setTestsPassed(prevState => [...prevState, "Add City For Artist"]))
                .catch(() => setTestsFailed(prevState => [...prevState, "Add City For Artist"]))*/


        })};

    return (
        <div >
            <h1 style={{ textAlign: "center" }}>Tests</h1>
            {backendEndpoints.map(endpointName => {
                const color = testsPassed.includes(endpointName) ? 'green' :
                    testsFailed.includes(endpointName) ? 'red' : 'white';
                return (
                    <h5
                        style={{
                            textAlign: "center",
                            margin: "20px",
                            borderWidth: 2,
                            borderStyle: "solid",
                            display: "inline-block",
                            padding: "10px 30px",
                            borderColor: color,
                            color: color, // Apply the color for text as well
                        }}
                        key={endpointName}
                    >
                        {endpointName}
                    </h5>
                );
            })}
        </div>
    );
};

export default BackendTesting;
