import React from 'react';
import globalColors from "../style/globalColors";

type Props = {
    color?: string;
    height?: string;
    width?: string;
}

const ActivityIndicator: React.FC<Props> = (props) => {
    const {color, width, height} = props;

    return (
        <div>
            <div style={{
                border: '4px solid rgba(255, 255, 255, 0.3)',
                borderTop: `4px solid ${color ? color : globalColors.primaryLight0}`,
                borderRadius: '50%',
                width: width ? width : "40px",
                height: height ? height : "40px",
                animation: 'spin 1s linear infinite',
            }}></div>

            <style>
                {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
            </style>
        </div>
    );
};

export default ActivityIndicator;
