import React, {useEffect, useState} from 'react';
import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import styles from "../style/components/CalendarModal.module.scss";
import {CitySelectionTimeFrame} from "../backend/apiService";

type Props = {
    onClose: () => void;
    initialDateRange: CitySelectionTimeFrame;
    onSubmit: (dateRange: CitySelectionTimeFrame, startDate: number | undefined, endDate: number | undefined) => void;
}

const CalendarModal: React.FC<Props> = ({ onClose, initialDateRange, onSubmit }) => {
    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    const [dateRange, setDateRange] = useState<CitySelectionTimeFrame>(initialDateRange);

    useEffect(() => {
        if (initialDateRange !== CitySelectionTimeFrame.CUSTOM) {
            updateDateRange(initialDateRange);
        }
    }, [initialDateRange]);

    const updateDateRange = (range: CitySelectionTimeFrame) => {
        setDateRange(range);

        let startDate = new Date();
        let endDate = new Date();

        switch (range) {
            case CitySelectionTimeFrame.DAYS_7:
                endDate.setDate(startDate.getDate() + 7);
                break;
            case CitySelectionTimeFrame.DAYS_30:
                endDate.setDate(startDate.getDate() + 30);
                break;
            case CitySelectionTimeFrame.DAYS_90:
                endDate.setDate(startDate.getDate() + 90);
                break;
            case CitySelectionTimeFrame.ALL:
                endDate = new Date(2099, 11, 31); // Set an arbitrary future date
                break;
            default:
                return;
        }

        setSelectionRange({ startDate, endDate, key: 'selection' });
    };

    const handleSelect = (ranges: any) => {
        setSelectionRange(ranges.selection);
        setDateRange(CitySelectionTimeFrame.CUSTOM);
    };

    return (
        <div className={styles["modal-overlay"]} onClick={onClose}>
            <div className={styles["modal"]} onClick={(e) => e.stopPropagation()}>
                <h3 className={styles["title"]}>Select Event Range</h3>
                <div className={styles["date-range-container"]}>
                    <h6
                        className={dateRange === CitySelectionTimeFrame.DAYS_7 ? styles["active"] : ""}
                        onClick={(e) => {
                            e.stopPropagation();
                            updateDateRange(CitySelectionTimeFrame.DAYS_7);
                        }}
                    >
                        Next 7 Days
                    </h6>
                    <h6
                        className={dateRange === CitySelectionTimeFrame.DAYS_30 ? styles["active"] : ""}
                        onClick={(e) => {
                            e.stopPropagation();
                            updateDateRange(CitySelectionTimeFrame.DAYS_30);
                        }}
                    >
                        Next 30 Days
                    </h6>
                    <h6
                        className={dateRange === CitySelectionTimeFrame.DAYS_90 ? styles["active"] : ""}
                        onClick={(e) => {
                            e.stopPropagation();
                            updateDateRange(CitySelectionTimeFrame.DAYS_90);
                        }}
                    >
                        Next 90 Days
                    </h6>
                </div>
                <div className={styles["date-range-container"]}>
                    <h6
                        className={dateRange === CitySelectionTimeFrame.ALL ? styles["active"] : ""}
                        onClick={(e) => {
                            e.stopPropagation();
                            updateDateRange(CitySelectionTimeFrame.ALL);
                        }}
                    >
                        All Time
                    </h6>
                    <h6
                        className={dateRange === CitySelectionTimeFrame.CUSTOM ? styles["active"] : ""}
                        onClick={(e) => {
                            e.stopPropagation();
                            setDateRange(CitySelectionTimeFrame.CUSTOM);
                        }}
                    >
                        Custom Range
                    </h6>
                </div>
                <DateRange
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    rangeColors={["#E84865"]}
                    className={styles["calendar"]}
                    minDate={new Date()} // Disable dates before today
                />
                <div className={styles["bottom-button-container"]}>
                    <h6 onClick={(e) => {
                        e.stopPropagation();
                        if (dateRange === CitySelectionTimeFrame.CUSTOM)
                            onSubmit(dateRange, selectionRange.startDate.getTime(), selectionRange.endDate.getTime());
                        else
                            onSubmit(dateRange, undefined, undefined);
                    }}>Done</h6>
                </div>
            </div>
        </div>
    );
};

export default CalendarModal;
