const globalColors = {
    primary: '#E84865',
    secondary: '#5b9bf3',
    spotifyGreen: '#1DB954',
    googleBlue: '#4285F4',


    dark: '#282c34',
    ligherDark1: '#30353f',
    lighterDark2: '#3c414d',
    lighterDark3: '#464d5b',
    lighterDark4: '#515969',

    primaryLight1: '#FBA2B2',
    primaryLight2: '#F17A8A',
    primaryDark1: '#D03E4F',
    primaryDark2: '#B52F3D',

    primaryLight0: '#ff5876',

    secondaryLight: '#82b5fd',
    secondaryLight2: '#e1e4ee',

    secondaryDark: "#636379",
    secondaryDark2: '#2a2a33',
    secondaryDark3: "#0e0e11",

    black: '#000000',
    grey1: '#0F0F0F',
    grey2: '#1F1F1F',
    grey3: '#2F2F2F',
    grey4: '#3F3F3F',
    grey5: '#4F4F4F',
    grey6: '#5F5F5F',
    grey7: '#6F6F6F',
    grey8: '#7F7F7F',
    grey9: '#8F8F8F',
    grey10: '#9F9F9F',
    grey11: '#AFAFAF',
    grey12: '#CECECE',
    grey13: '#E3E3E3',
    white: '#FFFFFF',
    background: `#282c34`
};

export default globalColors;
