import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { UserDetails } from "../backend/apiService";

interface UserContextType {
    userInfo: UserDetails | undefined;
    setUserInfo: (user: UserDetails | undefined) => void;
};

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [userInfo, setUserInfo] = useState<UserDetails | undefined>(undefined);

    useEffect(() => {
        const savedUser = localStorage.getItem('userInfo');
        if (savedUser) setUserInfo(JSON.parse(savedUser));
    }, []);

    useEffect(() => {
        if (userInfo) localStorage.setItem('userInfo', JSON.stringify(userInfo));
        else localStorage.removeItem('userInfo');
    }, [userInfo]);

    return (
        <UserContext.Provider value={{ userInfo, setUserInfo }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = (): UserContextType => {
    const context = useContext(UserContext);
    if (!context) throw new Error("useUser must be used within a UserProvider");
    return context;
};
