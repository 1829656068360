import React, {useEffect, useState} from 'react';
import * as Store from "../backend/storage";
import * as apiService from "../backend/apiService";
import * as loginApi from "../backend/loginApi"
import {LoginStrategy, MergeableAccount} from "../backend/loginApi"
import {useNavigate} from 'react-router-dom';
import "../style/App.scss"
import ActivityIndicator from "../components/ActivityIndicator";
import MergeAccountsModal from "../components/MergeAccountsModal";

const SpotifyLinkRedirect = () => {
    const navigate = useNavigate();

    const [mergeableAccount, setMergeableAccount] = useState<MergeableAccount | undefined>(undefined);

    useEffect(() => {
        const mergeableAccountHandler = async () => {
            const queryParams = new URLSearchParams(window.location.search);
            const mergeableToken = queryParams.get("mergeableToken");

            if (mergeableToken == undefined)
                navigate('/user/settings')
            else {
                apiService.getMergeableAccount(mergeableToken).then((mergeable) => {
                    console.log(mergeable);
                    setMergeableAccount(mergeable);
                }).catch((error) => {
                    console.log("Error retrieving mergeable account");
                });
            }
        }
        mergeableAccountHandler().then(r => r);
    }, [])

    const handleSubmitMerge = async () => {
        loginApi.handleAccountMerge(mergeableAccount?.id, mergeableAccount?.authToken, false).then(async creds => {
            if (creds.token) {
                await Store.storeTokenData(creds);
                Store.storeUserLoginStrategy(LoginStrategy.SPOTIFY);
                const nextPage = '/user/settings';
                navigate(nextPage);
            } else {
                throw new Error("Token is null")
            }
        })
    }

    return (
        <div className="App-body">
            <div className={"center-loading-button"}>
                <center><ActivityIndicator/><p>Connecting to Spotify...</p></center>
            </div>
            {mergeableAccount && (
                <MergeAccountsModal
                    onClose={() => navigate('/user/settings')}
                    mergeableAccount={mergeableAccount}
                    onSubmit={handleSubmitMerge}
                    fromSpotify={true}
                    email={undefined}
                />
            )}
        </div>
    )
}

export default SpotifyLinkRedirect
