import axios, { AxiosResponse } from "axios";
import api from "./axiosInstance";
import {getTokenData} from "./storage";

const noAuthApi = axios.create({
  baseURL: 'https://api.localify.org',
  // baseURL: 'http://localhost:8080',
});

export enum LoginStrategy {
  GUEST = "GUEST",
  EMAIL = "EMAIL",
  SPOTIFY = "SPOTIFY"
}

export interface AuthCredentials {
  token: string;
  refreshToken: string;
  expiresIn: number;
  error?: string;
}

export interface MergeableAccount {
  id: string;
  fromUserId: string;
  toUserId: string;
  authToken: string;
  expiresAt: number;
  fromUserName: string;
  fromUserEmail: string;
  fromUserCreatedAt: number;
  fromUserSpotifyUsername: string;
  mergedAccountPreview: MergedAccountPreview;
}

export interface MergedAccountPreview {
  name: string;
  email: string;
  spotifyName: string;
  fromUserCreatedAt: number;
  fromUserIsSpotifyConnected: boolean;
}

export interface AuthCredentialsMergeable {
  authCredentials: AuthCredentials;
  mergeableAccount: MergeableAccount;
}

export const userHasToken = (): boolean => {
  return getTokenData() !== undefined;
}

export const createGuestAccount = async (): Promise<AuthCredentials> => {
  try {
    const response: AxiosResponse<AuthCredentials> = await api.post(`/v1/auth/guest`);
    return response.data;
  } catch (error) {
    throw new Error('Error creating Guest Account.');
  }
}

export const handlePinEnter = async (nonce: string | null, pin: string): Promise<AuthCredentials> => {
  try {
    const response: AxiosResponse<AuthCredentials> = await api.post(`v1/auth/email/login`, { nonce: nonce, code: pin });
    return response.data;
  } catch (error) {
    throw new Error('Error logging in with email')
  }
}

export const handlePinEnterLink = async (nonce: string | null, pin: string): Promise<AuthCredentialsMergeable> => {
  try {
    const response: AxiosResponse<AuthCredentialsMergeable> = await api.post(`v1/@me/email/link`, { nonce: nonce, code: pin });
    return response.data;
  } catch (error) {
    throw new Error('Error logging in with email')
  }
}

export const handlePinEnterChangeEmail = async (nonce: string | null, pin: string): Promise<AuthCredentialsMergeable> => {
  try {
    const response: AxiosResponse<AuthCredentialsMergeable> = await api.post(`v1/@me/email`, { nonce: nonce, code: pin });
    return response.data;
  } catch (error) {
    throw new Error('Error logging in with email')
  }
}

export const handleAccountMerge = async (mergeRequestId: string | undefined, token: string | undefined, changeEmail: boolean): Promise<AuthCredentials> => {
  try {
    const response: AxiosResponse<AuthCredentials> = await api.post('v1/@me/merge', {mergeRequestId: mergeRequestId, token: token, changeEmail: changeEmail});
    return response.data;
  } catch (error) {
    throw new Error('Error merging accounts')
  }
}

export const createSpotifyAccount = async (secret: string, code: string): Promise<AuthCredentials> => {
  try {
    const json = {
      secret: secret,
      token: code
    }
    const response: AxiosResponse<AuthCredentials> = await api.post(`v1/auth/token`, json);
    return response.data;
  }
  catch {
    throw new Error('Error creating Spotify Account')
  }
}

export const createAppleAccount = async (name: string | undefined, token: string): Promise<AuthCredentials> => {
  try {
    const json = {
      name: name,
      token: token
    }
    const response: AxiosResponse<AuthCredentials> =  await api.post(`v1/auth/apple/token`, json)
    return response.data
  }
  catch {
    throw new Error('Error creating Apple Account')
  }
}

export const refreshToken = async (refreshToken: string): Promise<AuthCredentials> => {
  try {
    const json = {
      token: refreshToken
    }
    const response: AxiosResponse<AuthCredentials> = await api.post(`v1/auth/refresh`, json);
    return response.data;
  }
  catch {
    throw new Error('Error fetching new refreshToken');
  }
}
