import React, { useState, useEffect, useRef } from 'react';
import styles from "../style/components/ArtistCityModals.module.scss";
import * as apiService from "../backend/apiService";
import { ArtistResponse, CityResponse } from "../backend/apiService";
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import { getUserLoginStrategy } from "../backend/storage";
import { LoginStrategy } from "../backend/loginApi";
import { useNavigate } from "react-router-dom";
import * as stringFormatting from "../utils/StringFormatting";

type Props = {
    artist: ArtistResponse;
    onClose: () => void;
};

const AddCityToArtistModal: React.FC<Props> = ({ artist, onClose }) => {
    const navigate = useNavigate();
    const [cities, setCities] = useState<CityResponse[]>([]);
    const [selected, setSelected] = useState<CityResponse[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [continueClicked, setContinueClicked] = useState<boolean>(false);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [citiesSubmitted, setCitiesSubmitted] = useState<boolean>(false);

    const inputRef = useRef<HTMLInputElement>(null);
    const isGuest = getUserLoginStrategy() === LoginStrategy.GUEST;

    const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const fetchData = async (query: string) => {
        setLoading(true);
        try {
            const response = await apiService.fetchSearchCities(query);
            setCities(response);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (inputValue.trim()) {
            fetchData(inputValue);
        } else {
            setCities([]);
        }
    }, [inputValue]);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    const handleClick = (city: CityResponse) => {
        if (!continueClicked) {
            setSelected(prevSelected =>
                prevSelected.some(selectedCity => selectedCity.id === city.id)
                    ? prevSelected.filter(selectedCity => selectedCity.id !== city.id)
                    : [...prevSelected, city]
            );
        }
    };

    const handleSubmit = async () => {
        setSubmitLoading(true);
        try {
            await Promise.all(selected.map(city => apiService.addCityForArtist(artist.id, city.id)));
            setCitiesSubmitted(true);
        } catch (error) {
            console.error(error);
        } finally {
            setSubmitLoading(false);
        }
    };

    const renderCityGrid = (citiesToRender: CityResponse[], isClickable: boolean) => (
        <div className={styles["city-grid"]}>
            {citiesToRender.map((city, index) => {
                const isSelected = selected.some(selectedCity => selectedCity.id === city.id);
                return (
                    <div
                        key={index}
                        onClick={() => isClickable && handleClick(city)}
                        className={`${styles["city-element"]} ${isSelected && isClickable ? styles["active"] : ''}`}
                        style={{ cursor: isClickable ? 'pointer' : 'default' }}
                    >
                        <h6>{stringFormatting.toLocationLabel(city.name, city.zoneCode, city.countryCode)}</h6>
                    </div>
                );
            })}
        </div>
    );

    return (
        <div className={styles["modal-overlay"]} onClick={onClose}>
            <div className={styles["modal"]} onClick={e => e.stopPropagation()}>
                {isGuest ? (
                    <div className={styles["text-container"]}>
                        <div className={styles["thank-you-message"]}>
                            <h2 className={styles["title"]}>Please Login to Contribute</h2>
                            <p>
                                For the sake of maintaining accurate information,
                                Localify does not permit guest users to contribute to artist or city profiles.
                                If you would like to help us, which we greatly appreciate, please sign in using
                                a different method.
                            </p>
                        </div>
                    </div>
                ) : !citiesSubmitted ? (
                    <div className={styles["text-container"]}>
                        <h3 className={styles["title"]}>
                            {`Add Cities to ${artist.name}'s Localify Profile`}
                        </h3>
                        {!continueClicked ? (
                            <div className={styles['input-wrapper']}>
                                <SearchIcon className={styles['input-icon']} style={{ fontSize: '0.75em' }} />
                                <input
                                    ref={inputRef}
                                    className={styles['input']}
                                    placeholder="Search Cities"
                                    value={inputValue}
                                    onChange={onTextChange}
                                />
                                {loading && <CircularProgress className={styles['loading-indicator']} size={24} thickness={5} />}
                            </div>
                        ) : (
                            <p className={styles["subtitle"]}>
                                {`Localify relies on your suggestions for city origins. Before submitting, are you sure
                                that the following ${selected.length === 1 ? "city is" : "cities are"} 
                                where ${artist.name} is local to?`}
                            </p>
                        )}
                        {!continueClicked ? renderCityGrid(cities, true) : renderCityGrid(selected, false)}
                    </div>
                ) : (
                    <div className={styles["thank-you-message"]}>
                        <h2>Thank you for your submission!</h2>
                        <p>
                            Localify truly appreciates your contribution to the local music community. If you don't see
                            your submission right away, don't worry, your city origin suggestions are most likely under
                            review by our team. Thanks again!
                        </p>
                    </div>
                )}
                <div className={styles["buttons"]}>
                    {!citiesSubmitted ? (
                        <h6 onClick={onClose}>{continueClicked ? "No, Cancel" : "Cancel"}</h6>
                    ) : (
                        <h6 style={{ borderColor: "transparent" }}></h6>
                    )}
                    {isGuest ? (
                        <h6
                            className={styles["active"]}
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate("/user/settings");
                            }}
                        >
                            Settings
                        </h6>
                    ) : !continueClicked ? (
                        <h6
                            onClick={() => setContinueClicked(true)}
                            className={selected.length > 0 ? styles["active"] : ""}
                        >
                            Continue
                        </h6>
                    ) : !citiesSubmitted ? (
                        <h6 onClick={handleSubmit} className={styles["active"]}>
                            {submitLoading ? <CircularProgress className={styles["submit-loading"]} /> : 'Yes, Submit'}
                        </h6>
                    ) : (
                        <h6 onClick={onClose} className={styles["active"]}>Done</h6>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddCityToArtistModal;
