import React from "react";
import styles from "../style/components/SpotifyModal.module.scss";

type Props = {
    onClose: () => void;
    onConnect: () => void;
}

const SpotifyModal: React.FC<Props> = ({ onClose, onConnect }) => {

    return (
        <div className={styles['modal-overlay']}>
            <div className={styles['modal']}>
                <div>
                    <div className={styles['image']}>
                        <h6>Insert Image here</h6>
                    </div>
                    <div className={styles['content']}>
                        <h3 className={styles['title']}>Connect with Spotify</h3>
                        <p className={styles['summary']}>
                            Connecting with Spotify allows Localify.org the ability to access
                            your <i>true</i> listening habits on Spotify to provide more accurate recommendations.
                        </p>
                    </div>
                </div>
                <div className={styles['buttons']}>
                    <h5 onClick={onClose}>Cancel</h5>
                    <h5 onClick={onConnect}>Connect</h5>
                </div>
            </div>
        </div>
    )
}

export default SpotifyModal;
