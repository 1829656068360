import React from 'react';
import OptInToggleSwitch from './OptInToggleSwitch';
import styles from '../style/components/OptInToggleSwitch.module.scss';

type ToggleSwitchWithLabelProps = {
    label: string;
    onToggle: (isOn: boolean) => void;
    initialState: boolean;
};

const ToggleSwitchWithLabel: React.FC<ToggleSwitchWithLabelProps> = ({ label, onToggle, initialState }) => {
    return (
        <div className={styles.toggleSwitchContainer}>
            <span className={styles.label}>{label}</span>
            <OptInToggleSwitch onToggle={onToggle} initialState={initialState} />
        </div>
    );
};

export default ToggleSwitchWithLabel;