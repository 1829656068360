import React, {useState} from 'react';
import VerificationInput from "react-verification-input";
import globalColors from "../style/globalColors";
import ActivityIndicator from "./ActivityIndicator";

type Props = {
    onComplete: ((value: string) => void);
    email: string;
    nonce: string;
    value: string;
    setValue: ((value: string) => void);
    numTries: number;
    verifyButtonActive: boolean;
    onClose: (() => void);
}

const EmailVerificationModal: React.FC<Props> = (props) => {
    const {onComplete, email, nonce, value, numTries, setValue, verifyButtonActive, onClose} = props;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: "auto",
                paddingBottom: 30
            }}
        >
            {
                numTries < 3 ?
                    <>
                        <h2 style={{marginBottom: 0, marginTop: 20}}>Verify Your Email Address</h2>
                        <h6 style={{
                            textAlign: "center",
                            marginTop: 10,
                            color: globalColors.grey11,
                            marginBottom: 45,
                            whiteSpace: "pre-wrap"
                        }}>
                            Check your inbox for the verification code sent to {"\n" + email}
                        </h6>
                        <h6 style={{marginTop: 0, marginBottom: 15, fontSize: 14}}>— Enter Code —</h6>
                        <VerificationInput
                            value={value}
                            onComplete={value => onComplete(value)}
                            autoFocus={true}
                            validChars={'0-9'}
                            onChange={value => setValue(value)}
                        />
                        <button
                            style={{
                                marginTop: 18,
                                border: "2px solid",
                                borderColor: value.length < 6 ? globalColors.grey11 : globalColors.primary,
                                backgroundColor: value.length < 6 ? globalColors.grey11 : globalColors.primary,
                                borderRadius: 8,
                                width: 250,
                                height: 40,
                                alignItems: "center",
                                justifyContent: "center",
                                color: globalColors.white,
                                fontSize: 18,
                                fontWeight: "bold",
                                position: "relative",
                                cursor: "pointer"
                            }}
                            disabled={value.length < 6}
                            onClick={() => onComplete(value)}
                        >
                            {!verifyButtonActive ? `Verify` : (
                                <div style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                }}>
                                    <ActivityIndicator color="white" width={"20px"} height={"20px"}/>
                                </div>
                            )}
                        </button>
                        <button
                            style={{
                                borderWidth: 0,
                                backgroundColor: globalColors.dark,
                                marginTop: 28,
                                color: globalColors.grey12,
                                fontSize: 14,
                                cursor: "pointer"
                            }}
                            onClick={() => onClose()}
                        >
                            Cancel
                        </button>
                    </>

                    :

                    <></>
            }
        </div>
    )
}

export default EmailVerificationModal;
