import React, {useEffect, useState} from 'react';
import * as apiService from "../backend/apiService";
import {ArtistResponse, EventResponse, FavoriteTimeFrame, FavoriteTypes, SeedResponse} from "../backend/apiService";
import {useNavigate, useParams} from "react-router-dom";
import styles from '../style/pages/SavedEntities.module.scss';
import BookmarkButton from "../components/BookmarkButton";
import * as stringFormatting from "../utils/StringFormatting";
import PageHeader, {HeaderTypes} from "../components/PageHeader";
import ListOfCitiesModal from "../components/ListOfCitiesModal";
import FeedbackFormButton from "../components/FeedbackFormButton";
import {IconButton} from "@mui/material";
import {ArrowRightIcon} from "@mui/x-date-pickers-pro";

enum GeneralFilter {
    FAVORITE_ARTISTS = 'Discovered Artists',
    FAVORITE_EVENTS_UPCOMING = 'Upcoming Events',
    FAVORITE_EVENTS_PREVIOUS = 'Past Events',
    FAMILIAR_ARTISTS = 'Familiar Artists'
}

const noImage = 'https://localify-cdn.s3.amazonaws.com/assets/web_assets/NoImage.jpeg';

const SavedEntities: React.FC = () => {
    const navigate = useNavigate();
    const {type} = useParams();
    const [seeds, setSeeds] = useState<SeedResponse[]>([]);
    //const [seedsActive, setSeedsActive] = useState<SeedResponse[]>([]);
    //const [seedsBlacklisted, setSeedsBlacklisted] = useState<SeedResponse[]>([]);
    const [artists, setArtists] = useState<ArtistResponse[]>([]);
    const [eventsUpcoming, setEventsUpcoming] = useState<EventResponse[]>([]);
    const [eventsPrevious, setEventsPrevious] = useState<EventResponse[]>([]);
    const [showEditCitiesModal, setShowEditCitiesModal] = useState<boolean>(false);
    const [currentFilter, setCurrentFilter] = useState<GeneralFilter | undefined>(undefined);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const artistResponse = await apiService.fetchFavorites(FavoriteTypes.artists);
                const eventUpcomingResponse = await apiService.fetchFavorites(FavoriteTypes.events, undefined, FavoriteTimeFrame.UPCOMING);
                const eventPreviousResponse = await apiService.fetchFavorites(FavoriteTypes.events, undefined, FavoriteTimeFrame.PREVIOUS);
                const seedResponse = await apiService.fetchAllUserSeeds();
                setArtists(artistResponse.content as ArtistResponse[]);
                setSeeds((seedResponse as SeedResponse[]).sort((a, b) => {
                    if (!a.isBlacklisted) {
                        if (!b.isBlacklisted) {
                            if (a.name <= b.name) return -1;
                            return 1;
                        }
                        return -1;
                    }
                    if (b.isBlacklisted) {
                        if (a.name <= b.name) return -1;
                        return 1;
                    }
                    return 1;
                }));
                setEventsUpcoming(eventUpcomingResponse.content as EventResponse[]);
                setEventsPrevious(eventPreviousResponse.content as EventResponse[]);
                //setSeedsActive((seedResponse as SeedResponse[]).filter(x => !x.isBlacklisted));
                //setSeedsBlacklisted((seedResponse as SeedResponse[]).filter(x => x.isBlacklisted));
            } catch {
                // TODO handle error
            }
        };

        fetchData().then();
    }, []);

    useEffect(() => {
        if (type) {
            if (type === "favorite-artists") {
                setCurrentFilter(GeneralFilter.FAVORITE_ARTISTS);
            } else if (type === "favorite-events-upcoming") {
                setCurrentFilter(GeneralFilter.FAVORITE_EVENTS_UPCOMING);
            } else if (type === "favorite-events-previous") {
                setCurrentFilter(GeneralFilter.FAVORITE_EVENTS_PREVIOUS);
            } else if (type === "familiar-artists") {
                setCurrentFilter(GeneralFilter.FAMILIAR_ARTISTS);
            }
        }
    }, [type]);

    useEffect(() => {
        if (currentFilter === GeneralFilter.FAVORITE_ARTISTS)
            window.history.replaceState({}, '', `/saved/favorite-artists`);
        else if (currentFilter === GeneralFilter.FAVORITE_EVENTS_UPCOMING)
            window.history.replaceState({}, '', `/saved/favorite-events-upcoming`);
        else if (currentFilter === GeneralFilter.FAVORITE_EVENTS_PREVIOUS)
            window.history.replaceState({}, '', `/saved/favorite-events-previous`);
        else if (currentFilter === GeneralFilter.FAMILIAR_ARTISTS)
            window.history.replaceState({}, '', `/saved/familiar-artists`);
    }, [currentFilter]);

    const toggleSeed = async (seed: SeedResponse) => {
        try {
            if (seed.isBlacklisted) {
                await apiService.unBlacklistSeedArtist(seed.id);
            } else {
                await apiService.blacklistSeedArtist(seed.id);
            }
            setSeeds(seeds.map((s) => {
                if (s.id === seed.id) return {...seed, isBlacklisted: !seed.isBlacklisted };
                else return s;
            }));
        } catch {
        }
    }

    const favoriteArtistCard = (artist: ArtistResponse, index: number) => {
        return (
            <div
                key={index}
                className={styles["flex-item"]}
                onClick={() => window.location.href = `/artist/${artist.id}/${stringFormatting.toSlug(artist.name)}`}
            >
                <img src={artist.image || noImage} alt={`Image ${index + 1}`}
                     className={styles["image"]}/>
                <BookmarkButton
                    initialIsFavorite={true}
                    type={FavoriteTypes.artists}
                    id={artist.id}
                    handleError={() => console.log("error")} //TODO set error
                />
                <h6 className={styles["text-under-image"]}>{artist.name}</h6>
            </div>
        );
    }

    const favoriteEventCard = (event: EventResponse, index: number) => {
        const dateCard = stringFormatting.formatDateCard(event.startTime);

        return (
            <div
                key={index}
                className={styles["flex-item"]}
                onClick={() => window.location.href = `/event/${event.id}/${stringFormatting.toSlug(event.name)}`}
            >
                <img src={event?.topArtists && event?.topArtists.length > 0 ? event?.topArtists[0]?.image : noImage} alt={`Image ${index + 1}`}
                     className={styles["image"]}/>
                <div className={styles["date-card"]}>
                    <h6>{dateCard.day}</h6>
                    <h4>{dateCard.month} {dateCard.dayOfMonth}</h4>
                    <h6>{dateCard.time}</h6>
                </div>
                <BookmarkButton
                    initialIsFavorite={true}
                    type={FavoriteTypes.events}
                    id={event.id}
                    handleError={() => console.log("error")} //TODO set error
                />
                <h6 className={styles["text-under-image"]}>{event.name}</h6>
            </div>
        );
    }

    const seedCard = (seedArtist: SeedResponse, index: number) => {
        return (
            <div
                key={index}
                className={`${styles["flex-item"]} ${seedArtist.isBlacklisted ? '' : styles['not-blacklisted']}`}
                onClick={() => toggleSeed(seedArtist)}
            >
                <img src={seedArtist.image || noImage} alt={`Image ${index + 1}`}
                     className={styles["image"]}/>
                <div
                    className={styles["view-artist-container"]}
                >
                    <IconButton
                        aria-label="view page"
                        onClick={() => window.location.href = `/artist/${seedArtist.id}/${stringFormatting.toSlug(seedArtist.name)}`}
                    >
                        <ArrowRightIcon className={styles["view-artist-icon"]}/>
                    </IconButton>
                </div>
                <h6 className={styles["text-under-image"]}>{seedArtist.name}</h6>
            </div>
        );
    }

    return (
        <div className={styles["main-container"]}>
            <PageHeader
                type={HeaderTypes.HOME}
            />
            <FeedbackFormButton />

            <div className={styles["title"]}>
                <h1 className={styles["title-cap"]}>
                    {currentFilter === GeneralFilter.FAVORITE_ARTISTS ? "Your discovered artists" :
                        currentFilter === GeneralFilter.FAVORITE_EVENTS_UPCOMING ? "Your upcoming events" :
                            currentFilter === GeneralFilter.FAVORITE_EVENTS_PREVIOUS ? "Your previous events" :
                                currentFilter === GeneralFilter.FAMILIAR_ARTISTS ? "Your familiar artists" :
                                    "Your bookmarked venues"}
                </h1>
                <div className={`${styles['switch-buttons']} ${currentFilter !== GeneralFilter.FAMILIAR_ARTISTS ? styles['switch-buttons-norec'] : ''}`}>
                    {Object.values(GeneralFilter).map((filter, index) => {
                        let artistFilters = [GeneralFilter.FAVORITE_ARTISTS, GeneralFilter.FAMILIAR_ARTISTS];
                        let eventFilters = [GeneralFilter.FAVORITE_EVENTS_UPCOMING, GeneralFilter.FAVORITE_EVENTS_PREVIOUS];
                        if (currentFilter !== undefined) {
                            if ((artistFilters.includes(currentFilter) && artistFilters.includes(filter))
                                || (eventFilters.includes(currentFilter) && eventFilters.includes(filter))) {
                                return (
                                    <h6
                                        key={index}
                                        className={currentFilter === filter ? styles.active : ''}
                                        onClick={() => setCurrentFilter(filter)}
                                    >
                                        {filter}
                                    </h6>
                                )
                            }
                        }
                    }
                    )}
                </div>
                {
                    currentFilter === GeneralFilter.FAVORITE_ARTISTS ? (<></>) :
                        currentFilter === GeneralFilter.FAVORITE_EVENTS_UPCOMING ? (<></>) :
                            currentFilter === GeneralFilter.FAVORITE_EVENTS_PREVIOUS ? (<></>) :
                                currentFilter === GeneralFilter.FAMILIAR_ARTISTS ? (
                                        <>
                                            <p className={styles["rec-blurb"]}>
                                                Familiar Artists are selected or imported from spotify and used for your recommendations. <br/>
                                                Discovered Artists are also used for your recommendations.
                                            </p>
                                        </>
                                    ) :
                                    "Your bookmarked venues"
                }
            </div>
            {
                currentFilter === GeneralFilter.FAVORITE_ARTISTS ?
                    (
                        <div className={styles['favorites-container']}>
                            {artists.map(favoriteArtistCard)}
                        </div>
                    )
                    : currentFilter === GeneralFilter.FAVORITE_EVENTS_UPCOMING ?
                        (
                            <div className={styles['favorites-container']}>
                                {eventsUpcoming.map(favoriteEventCard)}
                            </div>
                        )
                        : currentFilter === GeneralFilter.FAVORITE_EVENTS_PREVIOUS ?
                            (
                                <div className={styles['favorites-container']}>
                                    {eventsPrevious.map(favoriteEventCard)}
                                </div>
                            )
                            :
                            (
                                <div className={`${styles['favorites-container']}`}>
                                    {seeds.map(seedCard)}
                                </div>
                            )
                            // this approach was causing me issues, but i left the bones in case you want to try and make it work so there's labels on the sections
                            /*(
                                <>
                                    <p><b>Familiar Artists</b></p>
                                    <div className={`${styles['favorites-container']}`}>
                                        {seedsActive.map(seedCard)}
                                    </div>
                                    <p>Disabled Familiar Artists</p>
                                    <div className={`${styles['favorites-container']}`}>
                                        {seedsBlacklisted.map(seedCard)}
                                    </div>
                                </>
                            )*/
            }
            {showEditCitiesModal &&
                <ListOfCitiesModal
                    onClose={() => {
                        setShowEditCitiesModal(false);
                    }}
                />
            }
        </div>
    );
};

export default SavedEntities;
