import * as React from "react";
import {useState} from "react";
import styles from "../style/pages/ErrorModal.module.scss";

type Props = {
    errorMessage?: string;
    onTryAgain?: () => void;
    tryAgainMessage?: string;
    loading?: boolean;
    onClose: () => void;
    onCloseMessage: string;
}

const ErrorModal: React.FC<Props> = (props) => {
    const { errorMessage, onTryAgain, tryAgainMessage, loading, onClose, onCloseMessage } = props;

    const [requestTriedAgain, setRequestTriedAgain] = useState<boolean>(false);

    const handleTryAgain = () => {
        if (onTryAgain) {
            onTryAgain();
            setRequestTriedAgain(true);
        }
    };

    return (
        <div className={styles["error-modal"]}>
            <div className={styles["error-modal-content"]}>
                <h1>Error</h1>
                <h3 className={styles["error-message"]}>
                    {!requestTriedAgain ? errorMessage : "We failed to process your request. Our servers may be down or you may have an invalid link."}
                </h3>
                <div className={styles["error-modal-actions"]}>
                    {(onTryAgain && !requestTriedAgain) && (
                        <div onClick={handleTryAgain}>
                            {loading ? (
                                <div className={styles["loading-indicator"]}></div>
                            ) : (
                                <h6 className={styles["try-again-button"]}>{tryAgainMessage || "Try Again"}</h6>
                            )}
                        </div>
                    )}
                    {!requestTriedAgain ?
                        <div className={onTryAgain ? styles["gray11"] : styles["try-again-button"]} onClick={onClose}>
                            <h6>{onCloseMessage}</h6>
                        </div> :
                        <div className={styles["try-again-button"]} onClick={onClose}>
                            <h6>{onCloseMessage}</h6>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default ErrorModal;
