import * as Store from "../backend/storage";
import * as apiService from "../backend/apiService"

const handleLogin = async () => {
    try {
        const tokenData = Store.getTokenData();
        if (!tokenData)
            return "/login";

        const cities = await apiService.fetchUserCities();
        if (cities.current) {
            try {
                await apiService.fetchArtistRecommendations(cities.current.id);
                return "/recommendations";
            } catch (error) {
                return "/onboarding/location";
            }
        } else {
            return "/onboarding/location";
        }
    } catch (error) {
        return await apiService.fetchUserSeeds().then(res => {
            if (res.length > 0) return "/onboarding/location?loginType=artists";
            else return "/onboarding/location";

        }).catch(() => {
            return "/onboarding/location";
        })
    }
};


export default handleLogin;
