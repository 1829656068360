import React, {useEffect} from 'react';
import * as Store from "../backend/storage";
import * as loginApi from "../backend/loginApi"
import { useNavigate } from 'react-router-dom';
import handleLogin from "../utils/handleLogin";
import "../style/App.scss"
import ActivityIndicator from "../components/ActivityIndicator";

const EmailRedirect = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate("/user/settings")
    }, [])

    return (
        <div className={"center-loading-button"}>
            <center><ActivityIndicator/><p>Connecting Your Email...</p></center>
        </div>
    )
}

export default EmailRedirect
