import * as React from 'react';
import styles from '../style/components/OnboardingBreadcrumb.module.scss';
import * as apiService from "../backend/apiService";

import RoomRoundedIcon from '@mui/icons-material/RoomRounded';
import LibraryMusicRoundedIcon from '@mui/icons-material/LibraryMusicRounded';
import MicExternalOnRoundedIcon from '@mui/icons-material/MicExternalOnRounded';
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

export enum OnboardingStages {
    LOCATION = "location",
    GENRES = "genres",
    ARTISTS = "artists"
}

interface Props {
    onboardingStage: OnboardingStages;
    goBackGenres?: () => void;
    allowTravelArtists?: boolean;
    handleArtistButtonClicked?: () => void;
}

const OnboardingBreadcrumb: React.FC<Props> = ({onboardingStage, goBackGenres, allowTravelArtists, handleArtistButtonClicked}) => {
    const navigate = useNavigate();
    const [allowTravelGenres, setAllowTravelGenres] = useState<boolean>(false);

    useEffect(() => {
        if (onboardingStage === OnboardingStages.LOCATION) {
            apiService.fetchUserCities().then(cities => {
                if (cities.current) {
                    setAllowTravelGenres(true);
                }
            }).catch((error) => console.log("Must select city before heading to genres: " + error));
        } else {
            setAllowTravelGenres(true);
        }
    }, [])

    const handleLocationClicked = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        navigate("/onboarding/location", {replace: true});
    }

    const handleGenresClicked = (event: React.MouseEvent<HTMLDivElement>) => {
        if (goBackGenres)
            goBackGenres();

        if (allowTravelGenres) {
            event.stopPropagation();
            navigate("/onboarding/artists", {replace: true});
        }
    }

    const handleArtistsClicked = (event: React.MouseEvent<HTMLDivElement>) => {
        if (onboardingStage === OnboardingStages.GENRES && allowTravelArtists && handleArtistButtonClicked) {
            event.stopPropagation();
            handleArtistButtonClicked();
        }
    }

    return (
        <div className={styles['main-container']}>
            <div className={styles['connector-line']}></div>
            <div className={styles['breadcrumb-grid']}>
                <div className={styles['breadcrumb-section']}>
                    <div className={`${styles['breadcrumb-label']}
                    ${onboardingStage===OnboardingStages.LOCATION ? styles['active'] : ''}`}>
                        Find your location
                    </div>
                    <div
                        className={styles['outer-circle']}
                        onClick={handleLocationClicked}
                    >
                        <div className={`${styles['breadcrumb-icon']}
                        ${onboardingStage===OnboardingStages.LOCATION ? styles['active'] : ''}`}>
                            <RoomRoundedIcon/>
                        </div>
                    </div>
                </div>
                <div className={styles['breadcrumb-section']}>
                    <div className={`${styles['breadcrumb-label']} 
                    ${onboardingStage===OnboardingStages.GENRES ? styles['active'] : ''}`}>
                        Choose your genres
                    </div>
                    <div
                        className={styles['outer-circle']}
                        onClick={handleGenresClicked}
                    >
                        <div className={`${styles['breadcrumb-icon']} 
                        ${onboardingStage === OnboardingStages.GENRES ? styles['active'] :
                            !allowTravelGenres ? styles["inactive"] : ''}`}>
                            <LibraryMusicRoundedIcon className={styles["icon"]} />
                        </div>
                    </div>
                </div>
                <div className={styles['breadcrumb-section']}>
                    <div className={`${styles['breadcrumb-label']} 
                    ${onboardingStage === OnboardingStages.ARTISTS ? styles['active'] : ''}`}>
                        Select current faves
                    </div>
                    <div
                        className={styles['outer-circle']}
                        onClick={handleArtistsClicked}
                    >
                        <div className={`${styles['breadcrumb-icon']} 
                        ${onboardingStage === OnboardingStages.ARTISTS ? styles['active'] :
                            (allowTravelArtists && handleArtistButtonClicked) ? "" : styles["inactive"]}`}>
                            <MicExternalOnRoundedIcon className={styles["icon"]}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
)
}

export default OnboardingBreadcrumb
