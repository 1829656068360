import React, {useEffect, useState} from 'react';
import ActivityIndicator from "../components/ActivityIndicator";
import * as apiService from "../backend/apiService";
import {EventResponse, VenueResponse} from "../backend/apiService";
import {useNavigate, useParams} from "react-router-dom";
import globalColors from "../style/globalColors";
import ListOfCitiesModal from "../components/ListOfCitiesModal";
import EventListCard from "../components/EventListCard";
import PageHeader, {HeaderTypes} from "../components/PageHeader";
import * as stringFormatting from "../utils/StringFormatting";
import styles from "../style/pages/DetailPages.module.scss";
import CityIcon from "../assets/CityIcon.png";
import ErrorModal from "../components/ErrorModal";
import {userHasToken} from "../backend/loginApi";
import FeedbackFormButton from "../components/FeedbackFormButton";

const VenueDetail = () => {
    const navigate = useNavigate();
    let {id, slug} = useParams();
    const userLoggedIn = userHasToken();

    const [loading, setLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [showEditCitiesModal, setShowEditCitiesModal] = useState<boolean>(false);

    const [venue, setVenue] = useState<VenueResponse | undefined>(undefined);
    const [events, setEvents] = useState<EventResponse[] | undefined>(undefined);

    // useEffect to handle window resize and fetch user details
    useEffect(() => {
        const handleBackButton = () => {
            const currentPath = window.location.pathname;
            const pathRegex = /^\/artist\/[^\/]+\/[^\/]+$/;

            if (pathRegex.test(currentPath)) {
                // Extract id and slug from the currentPath if needed
                // Execute your logic for this specific path
                if (id && slug) {
                    changeVenue(id, slug);
                }
            }
        }

        window.addEventListener('popstate', handleBackButton);

        // Initial data fetch logic
        if (id) {
            getVenueInfo();
        } else {
            setErrorMessage("Failed to fetch venue details.");
        }

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [id, slug]);


    const getVenueInfo = async () => {
        setLoading(true);
        if (id) {
            try {
                const [venueData, events] = await Promise.all([
                    apiService.fetchVenue(id),
                    apiService.fetchVenueUpcomingEvents(id)
                ]);
                setVenue(venueData);
                setEvents(events);
                setLoading(false);
                setErrorMessage(undefined);
            } catch (error) {
                setErrorMessage("Failed to fetch venue details.");
                setLoading(false);
            }
        } else {
            setErrorMessage("Failed to fetch venue details.");
            setLoading(false);
        }
    };


    // Function to change venue when the id in the URL changes (example: searching for an artist while on artist detail page)
    const changeVenue = (id: string, name: string) => {
        navigate(`/artist/${id}/${stringFormatting.toSlug(name)}`)
        getVenueInfo();
    }

    const generalSection = () => {
        if (venue) {
            return (
                <div className={styles["main-section"]}>
                    <div className={styles["section-header"]}>
                        <h1 className={styles["no-margin"]}>{venue.name}</h1>
                    </div>
                    <h5 className={styles["no-margin"]}>{venue.address}</h5>
                </div>
            )
        }
    }

    const eventsSection = () => {
        if (events === undefined) {
            return (
                <div className={styles["main-section"]}>
                    <div className={styles["section-header"]}>
                        <h4 className={styles["no-margin"]}>Upcoming Events</h4>
                    </div>
                    <div style={{padding: "10px 0px 5px 0px"}}>
                        <ActivityIndicator width={"20px"} height={"20px"}/>
                    </div>
                </div>
            );
        } else if (events.length === 0) {
            return (
                <div className={styles["main-section"]}>
                    <div className={styles["section-header"]}>
                        <h4 className={styles["no-margin"]}>Upcoming Events</h4>
                    </div>
                    <div>
                        <h6 style={{
                            textAlign: "center",
                            marginTop: 20,
                            color: globalColors.grey11
                        }}>Venue has no events.</h6>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={styles["main-section"]}>
                    <div className={styles["section-header"]}>
                        <h4 className={styles["no-margin"]}>Upcoming Events</h4>
                    </div>
                    <div>
                        {events.map((event, index) => (
                            <div
                                key={index}
                                onClick={() => navigate(`/event/${event.id}/${stringFormatting.toSlug(event.name)}`)}
                            >
                                <EventListCard event={event}/>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
        ;
    };

    return (
        <div className="App-body">
            <PageHeader
                type={userLoggedIn ? HeaderTypes.HOME : HeaderTypes.LANDING}
            />
            <FeedbackFormButton />

            {loading ?
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}>
                    <ActivityIndicator/>
                </div> :
                (venue &&
                    <div style={{flex: 1, marginTop: 60, marginBottom: 100}}>
                        <div className={styles["container"]}>
                            <div className={styles["side"]}
                                 style={{flexDirection: "column", justifyContent: "start", marginTop: 40}}>
                                <img
                                    alt="" className={styles["detail-image"]}
                                     src={CityIcon}
                                    style={{width: "90%"}}
                                />
                                <h6
                                    className={styles["visit-maps-button"]}
                                    onClick={() => window.open(`https://www.google.com/maps/place/
                                            ${venue.address}+${venue.city.name}+
                                            ${venue.city.zoneCode ? venue.city.zoneCode : venue.city.countryCode}`,
                                        '_blank')}
                                >Get Directions</h6>
                            </div>
                            <div className={styles["main"]}>
                                <div className={styles["show-over-1250"]}>{generalSection()}</div>
                                <div className={styles["show-over-1250"]}>{eventsSection()}</div>
                            </div>
                            <div style={{flex: 1, width: "100%"}}>
                                <div className={styles["show-under-1249"]}>{generalSection()}</div>
                                <div className={styles["show-under-1249"]}>{eventsSection()}</div>
                            </div>
                        </div>
                    </div>
                )
            }
            {errorMessage &&
                <ErrorModal
                    errorMessage={errorMessage}
                    onTryAgain={getVenueInfo}
                    onClose={() => navigate('/')}
                    onCloseMessage="Back to Home"
                />
            }
            {showEditCitiesModal &&
                <ListOfCitiesModal
                    onClose={() => {
                        setShowEditCitiesModal(false);
                    }}
                />
            }
        </div>
    )
}

export default VenueDetail;
