import React, {useEffect, useState} from 'react';
import ActivityIndicator from "../components/ActivityIndicator";
import * as apiService from "../backend/apiService";
import {EventResponse, FavoriteTypes} from "../backend/apiService";
import {useNavigate, useParams} from "react-router-dom";
import globalColors from "../style/globalColors";
import PageHeader, {HeaderTypes} from "../components/PageHeader";
import * as stringFormatting from "../utils/StringFormatting";
import ListOfCitiesModal from "../components/ListOfCitiesModal";
import CityIcon from "../assets/CityIcon.png";
import styles from "../style/pages/DetailPages.module.scss";
import ErrorModal from "../components/ErrorModal";
import BookmarkButton from "../components/BookmarkButton";
import AddIcon from '@mui/icons-material/Add';
import {userHasToken} from "../backend/loginApi";
import FeedbackFormButton from "../components/FeedbackFormButton";

enum ErrorTypes {
    NO_ID_PRESENT = "No ID present",
    FAILED_TO_FETCH = "Failed to fetch",
    FAILED_TO_FAVORITE = "Failed to favorite"
}

const noImage = 'https://localify-cdn.s3.amazonaws.com/assets/web_assets/NoImage.jpeg';

const EventDetail = () => {
    const navigate = useNavigate();
    let {id, slug} = useParams();
    const userLoggedIn = userHasToken();

    const [loading, setLoading] = useState<boolean>(true);
    const [errorType, setErrorType] = useState<ErrorTypes | undefined>();
    const [showEditCitiesModal, setShowEditCitiesModal] = useState<boolean>(false);

    const [event, setEvent] = useState<EventResponse | undefined>(undefined);

    // useEffect to handle window resize and fetch user details
    useEffect(() => {
        const handleBackButton = () => {
            const currentPath = window.location.pathname;
            const pathRegex = /^\/event\/[^\/]+\/[^\/]+$/;

            if (pathRegex.test(currentPath)) {
                // Extract id and slug from the currentPath if needed
                // Execute your logic for this specific path
                if (id && slug) {
                    changeEvent(id, slug);
                }
            }
        }
        window.addEventListener('popstate', handleBackButton);

        // Initial data fetch logic
        if (id) {
            getEventInfo(id);
        } else {
            setErrorType(ErrorTypes.NO_ID_PRESENT);
        }

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [id, slug]);


    const getEventInfo = (id: string) => {
        apiService.fetchEvent(id).then(eventInfo => {
            setEvent(eventInfo);
            setLoading(false);
        }).catch(error => {
            console.log("Event not found: " + error);
            setErrorType(ErrorTypes.FAILED_TO_FETCH)
            setLoading(false);
        });
    }

    //Function to change artist when the id in the URL changes (example: searching for an artist while on artist detail page)
    const changeEvent = (id: string, name: string) => {
        navigate(`/event/${id}/${stringFormatting.toSlug(name)}`);
        getEventInfo(id);
    }

    const generalSection = () => {
        if (event) {
            return (
                <div className={styles["main-section"]}>
                    <div className={styles["section-header"]}>
                        <h1 className={styles["no-margin"]}>{event.name}</h1>
                    </div>
                    <h5>{stringFormatting.toLocationLabel(event.venue.city.name, event.venue.city.zoneCode, event.venue.city.countryCode)}</h5>
                    <h6>{stringFormatting.formatEventDate(event.startTime).toString()}</h6>
                </div>
            )
        }
    }

    const performingArtistsSection = () => {
        if (event?.topArtists === undefined) {
            return (
                <div className={styles["main-section"]}>
                    <div className={styles["section-header"]}>
                        <h4 className={styles["no-margin"]}>Performing Artists</h4>
                    </div>
                    <div style={{padding: "10px 0px 5px 0px"}}>
                        <ActivityIndicator width={"20px"} height={"20px"}/>
                    </div>
                </div>
            );
        } else if (event?.topArtists.length === 0) {
            return (
                <div className={styles["main-section"]}>
                    <div className={styles["section-header"]}>
                        <h4 className={styles["no-margin"]}>Performing Artists</h4>
                    </div>
                    <h6 style={{textAlign: "center", marginTop: 20, color: globalColors.grey11}}>Event is missing
                        performing artists.</h6>
                </div>
            );
        } else {
            return (
                <div className={styles["main-section"]}>
                    <div className={styles["section-header"]}>
                        <h4 className={styles["no-margin"]}>Performing Artists</h4>
                    </div>
                    <div className={styles["artist-container"]}>
                        {event.topArtists.map((performingArtist, index) => (
                            <div
                                key={index}
                                title={performingArtist.name}
                                className={styles["artist-card"]}
                                onClick={() => navigate(`/artist/${performingArtist.id}/${stringFormatting.toSlug(performingArtist.name)}`)}
                            >
                                <img alt={performingArtist.name} src={performingArtist.image || noImage} />
                                <h5 className={styles["artist-name"]}>{performingArtist.name}</h5>
                                {performingArtist.city ? (
                                    <h6 className={styles["artist-city"]}>
                                        {stringFormatting.toLocationLabel(performingArtist.city.name, performingArtist.city.zoneCode,  performingArtist.city.countryCode)}
                                    </h6> ) : (
                                    <div
                                        className={styles["no-artist-city"]}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(`/artist/${performingArtist.id}/${stringFormatting.toSlug(performingArtist.name)}?open-city-modal=true`);
                                        }}
                                    >
                                        <AddIcon className={styles["icon"]} />
                                        <h6>Add a City</h6>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )
        }
    }

    const fetchErrorMessage = () => {
        if (errorType === ErrorTypes.NO_ID_PRESENT)
            return "The city you're looking for couldn't be found.";
        else if (errorType === ErrorTypes.FAILED_TO_FETCH)
            return "Failed to fetch City Details.";
    }

    const handleRetry = () => {
        if (errorType === ErrorTypes.FAILED_TO_FETCH)
            if (id) {
                getEventInfo(id);
            } else {
                setErrorType(ErrorTypes.NO_ID_PRESENT);
            }
    }
    return (
        <div className="App-body">
            {errorType &&
                <ErrorModal
                    errorMessage={fetchErrorMessage()}
                    onTryAgain={!ErrorTypes.NO_ID_PRESENT ? undefined : handleRetry}
                    onClose={() => navigate("/")}
                    onCloseMessage={"Back to home"}
                />}
            <PageHeader
                type={userLoggedIn ? HeaderTypes.HOME : HeaderTypes.LANDING}
            />
            <FeedbackFormButton />
            {loading ?
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}>
                    <ActivityIndicator/>
                </div> :
                (event &&
                    <div style={{flex: 1, marginTop: 60, marginBottom: 100}}>
                        <div className={styles["container"]}>
                            <div className={styles["side"]}
                                 style={{flexDirection: "column", justifyContent: "start", marginTop: 40}}>
                                <div style={{position: "relative", width: "80%"}}>
                                    <img alt="" className={styles["detail-image"]} src={CityIcon}/>
                                    <BookmarkButton
                                        initialIsFavorite={event.isFavorite}
                                        type={FavoriteTypes.events}
                                        id={event.id}
                                        handleError={() => setErrorType(ErrorTypes.FAILED_TO_FAVORITE)}
                                    />
                                </div>
                                {event.ticketUrl &&
                                    <h6
                                        className={styles["get-tickets-button"]}
                                        onClick={() => window.open(event?.ticketUrl, '_blank')}
                                    >View Event Tickets</h6>
                                }
                            </div>
                            <div className={styles["main"]}>
                                <div className={styles["show-over-1250"]}>{generalSection()}</div>
                                <div className={styles["show-over-1250"]}>{performingArtistsSection()}</div>
                            </div>
                            <div style={{flex: 1, width: "100%"}}>
                                <div className={styles["show-under-1249"]}>{generalSection()}</div>
                                <div className={styles["show-under-1249"]}>{performingArtistsSection()}</div>
                            </div>
                        </div>
                    </div>
                )
            }
            {showEditCitiesModal &&
                <ListOfCitiesModal
                    onClose={() => {
                        setShowEditCitiesModal(false);
                    }}
                />
            }
        </div>
    )
}

export default EventDetail;
