import React, {useEffect, useRef, useState} from "react";
import GoogleMapReact from 'google-map-react'; // Import Maps type
import styles from '../style/pages/ChooseLocation.module.scss';
import globalColors from "../style/globalColors";
import OnboardingBreadcrumb, {OnboardingStages} from '../components/OnboardingBreadcrumb';
import PageHeader, {HeaderTypes} from "../components/PageHeader";
import ChooseLocationSearch from "../components/ChooseLocationSearch";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import * as apiService from "../backend/apiService";
import {CityResponse} from "../backend/apiService";

export enum ProcedureTypes {
    ONBOARDING = "onboarding",
    ADD = "add",
    EDITRADIUS = "edit-radius"
}

export interface MapViewState {
    longitude: number;
    latitude: number;
    zoom: number;
}

const mapStyle = [
    {
        "featureType": "administrative",
        "elementType": "labels",
        "stylers": [
            { "visibility": "off" }
        ]
    },
    {
        "featureType": "administrative.country",
        "elementType": "labels",
        "stylers": [
            { "visibility": "on" }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "labels",
        "stylers": [
            { "visibility": "on" }
        ]
    },
    {
        "featureType": "administrative.province",
        "elementType": "labels",
        "stylers": [
            { "visibility": "on" }
        ]
    },
    {
        "featureType": "administrative.neighborhood",
        "elementType": "labels",
        "stylers": [
            { "visibility": "off" }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
            { "visibility": "off" }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            { "visibility": "on" }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels",
        "stylers": [
            { "visibility": "on" }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels",
        "stylers": [
            { "visibility": "on" }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "labels",
        "stylers": [
            { "visibility": "off" }
        ]
    }
];

const mapOptions = {
    fullscreenControl: false,
    styles: mapStyle
};

enum ErrorTypes {
    FAILED_TO_SEARCH = "Failed to search",
    FAILED_TO_FETCH_CITY_DETAILS = "Failed to fetch city details",
    FAILED_TO_SUBMIT = "Failed to submit"
}

const ChooseLocation: React.FC = () => {
    const { procedure } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const mapRef = useRef<any | null>(null);
    const [citySelected, setCitySelected] = useState<boolean>(false);
    const [currentCity, setCurrentCity] = useState<CityResponse | undefined>(undefined);
    const [viewState, setViewState] = useState<MapViewState>({
        latitude: 42.4440,
        longitude: -76.5019,
        zoom: 12
    });
    const [circleCenter, setCircleCenter] = useState<{ lat: number; lng: number }>({ lat: 42.4440, lng: -76.5019 });
    const [circleRadius, setCircleRadius] = useState<number>(15); // Initial radius in miles

    const [googleMaps, setGoogleMaps] = useState<any>(null);

    const circleRef = useRef<any>(null);

    const createGoogleMapsCircle = (center: { lat: number, lng: number }, radius: number): google.maps.Circle => {
        return new google.maps.Circle({
            center,
            radius: radius * 1609.34, // Convert miles to meters
            strokeColor: globalColors.primary,
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: globalColors.primary,
            fillOpacity: 0.35
        });
    };

    const updateCircle = (center: { lat: number; lng: number }, newRadius: number) => {
        setCircleCenter(center);
        setCircleRadius(newRadius);

        if (mapRef.current && googleMaps) {
            if (circleRef.current) {
                circleRef.current.setMap(null);
            }

            const newCircle = createGoogleMapsCircle(center, newRadius);
            newCircle.setMap(mapRef.current);

            circleRef.current = newCircle;
        }
    };

    useEffect(() => {
        const lat = viewState?.latitude ? viewState.latitude : 0;
        const lng = viewState?.longitude ? viewState.longitude : 0;

        if (circleRadius <= 3) {
            setViewState({latitude: lat, longitude: lng, zoom: 12 });
            updateCircle({ lat, lng }, citySelected ? circleRadius : 0);
        } else if (circleRadius <= 7) {
            setViewState({latitude: lat, longitude: lng, zoom: 11 });
            updateCircle({ lat, lng }, citySelected ? circleRadius : 0);
        } else if (circleRadius <= 15) {
            setViewState({latitude: lat, longitude: lng, zoom: 10 });
            updateCircle({ lat, lng }, citySelected ? circleRadius : 0);
        } else if (circleRadius <= 28) {
            setViewState({latitude: lat, longitude: lng, zoom: 9 });
            updateCircle({ lat, lng }, citySelected ? circleRadius : 0);
        } else {
            setViewState({latitude: lat, longitude: lng, zoom: 8 });
            updateCircle({ lat, lng }, citySelected ? circleRadius : 0);
        }
    }, [circleRadius, citySelected]);

    useEffect(() => {
        return () => {
            if (circleRef.current) {
                circleRef.current.setMap(null);
            }
        };
    }, []);

    useEffect(() => {
        handleProcedureType();
    },[]);

    useEffect(() => {
        if (currentCity && googleMaps) {
            setViewState({
                latitude: currentCity.latitude,
                longitude: currentCity.longitude,
                zoom: 10
            });

            const googleMaps = window.google.maps;
            const latLng = new googleMaps.LatLng(currentCity.latitude, currentCity.longitude);
            mapRef.current.panTo(latLng);

            updateCircle({ lat: currentCity.latitude, lng: currentCity.longitude }, circleRadius);
        }
    }, [currentCity, googleMaps]);

    const handleApiLoaded = ({map, maps}: {map: any, maps: any}) => {
        mapRef.current = map;
        setGoogleMaps(window.google);

        const initialCircle = createGoogleMapsCircle(circleCenter, circleRadius);
        initialCircle.setMap(map);

        circleRef.current = initialCircle;

        map.setOptions({
            zoomControl: true,
            zoomControlOptions: {
                position: maps.ControlPosition.RIGHT_CENTER
            }
        });
    };

    const continueClicked = async () => {
        if (citySelected && currentCity) {
            await apiService.putUserCity(currentCity.id, circleRadius);
            if (procedure === "onboarding")
                navigate("/onboarding/artists")
            else
                navigate("/recommendations")
        }
    }

    const handleProcedureType = () => {
        const fetchData = async () => {
            try {
                const nearestCities = await apiService.fetchUserNearestCities();
                if (nearestCities?.cities?.length !== 0) {
                    updateCircle({
                        lng: nearestCities.cities[0].longitude,
                        lat: nearestCities.cities[0].latitude
                    }, circleRadius);

                    const googleMaps = window.google.maps;
                    const latLng = new googleMaps.LatLng(nearestCities.cities[0].latitude, nearestCities.cities[0].longitude);
                    mapRef.current.panTo(latLng);
                    setViewState({
                        latitude: nearestCities.cities[0].latitude,
                        longitude: nearestCities.cities[0].longitude,
                        zoom: viewState?.zoom ? viewState.zoom : 0
                    });
                }
            } catch (error) {
                console.log(error);
            }
        };

        if (procedure === ProcedureTypes.ONBOARDING || procedure === ProcedureTypes.ADD) {
            fetchData().then();
        } else if (procedure === ProcedureTypes.EDITRADIUS) {
            const cityID = searchParams.get("id");
            const cityRadius = searchParams.get("radius");
            if (cityID) {
                apiService.fetchCityDetails(cityID).then(cityDetails => {
                    setCurrentCity(cityDetails);
                    if (cityRadius) {
                        setCircleRadius(parseFloat(cityRadius));
                        updateCircle({
                            lng: cityDetails.longitude,
                            lat: cityDetails.latitude
                        }, parseFloat(cityRadius));
                        const googleMaps = window.google.maps;
                        const latLng = new googleMaps.LatLng(cityDetails.latitude, cityDetails.longitude);
                        mapRef.current.panTo(latLng);
                        setViewState({
                            latitude: cityDetails.latitude,
                            longitude: cityDetails.longitude,
                            zoom: viewState?.zoom ? viewState.zoom : 0
                        });
                        setCitySelected(true);
                    } else {
                        console.log("No range submitted.");
                        navigate("/recommendations")
                    }
                }).catch(() => {
                    console.log("Error fetching city details.");
                });
            } else {
                console.log("No city ID present for edit city range.");
            }
        }
    };

    return (
        <div className={'App-body'}>
            {procedure === "onboarding" ?
                <PageHeader
                    type={HeaderTypes.DEFAULT}
                    backButtonString={"Back to Login"}
                    backButtonFunction={() => navigate("/login", {replace: true})}
                    continueText={"Choose Genres"}
                    continueDisabled={!citySelected}
                    continueButtonFunction={continueClicked}
                />
                :
                <PageHeader
                    type={HeaderTypes.HOME}
                    backButtonString={"Cancel"}
                    continueText={"Save City"}
                    continueDisabled={!citySelected}
                    continueButtonFunction={continueClicked}
                />
            }
            <div className={styles['onboarding-header']}>
                <ChooseLocationSearch
                    setCurrentCity={setCurrentCity}
                    setCitySelected={setCitySelected}
                    setCircleRadius={setCircleRadius}
                    updateCircle={updateCircle}
                    setViewState={setViewState}
                    currentCity={currentCity}
                    circleRadius={circleRadius}
                    viewState={viewState}
                    forceRadiusEditor={procedure === ProcedureTypes.EDITRADIUS}
                />
            </div>
            <div style={{ height: '100vh', width: '100%' }}>
                <GoogleMapReact
                    ref={mapRef}
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_TOKEN }}
                    center={{ lat: viewState.latitude, lng: viewState.longitude }}
                    zoom={viewState.zoom}
                    options={mapOptions}
                    onGoogleApiLoaded={handleApiLoaded}
                    yesIWantToUseGoogleMapApiInternals={true}
                >
                </GoogleMapReact>
            </div>
            {procedure === ProcedureTypes.ONBOARDING ?
                <section className={styles['breadcrumb-section']}>
                    <OnboardingBreadcrumb onboardingStage={OnboardingStages.LOCATION}/>
                </section> : ''
            }
        </div>
    );
}

export default ChooseLocation;
