import React, {useEffect, useRef, useState} from 'react';
import styles from "../style/components/RecommendationCard.module.scss";
import {EventRecResponse, FavoriteTypes} from "../backend/apiService";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import BookmarkButton from "./BookmarkButton";
import * as stringFormatting from "../utils/StringFormatting";
import Tooltip from "@mui/material/Tooltip";

import {PauseCircleOutline, PlayCircleOutline} from "@mui/icons-material";

type Props = {
    event: EventRecResponse;
    handleError: () => void;
    onPlay: (audio: HTMLAudioElement) => void;
}

const EventRecommendationCard: React.FC<Props> = ({event, handleError, onPlay}) => {
    const navigate = useNavigate();
    const city = event.venue.city;

    const dateCard = stringFormatting.formatDateCard(event.startTime);
    const audioRef = useRef<HTMLAudioElement>(null);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const noImage = 'https://localify-cdn.s3.amazonaws.com/assets/web_assets/NoImage.jpeg';


    const toggleAudio = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
                onPlay(audioRef.current);
            }
            setIsPlaying(!isPlaying);
        }
    };

    useEffect(() => {
        const audioElement = audioRef.current;

        const handlePause = () => setIsPlaying(false);

        if (audioElement) {
            audioElement.addEventListener('pause', handlePause);
        }

        return () => {
            if (audioElement) {
                audioElement.removeEventListener('pause', handlePause);
            }
        };
    }, []);

    const eventArtists = event.artists.map((artist, index) => {
        if (index === event.artists.length - 1) {
            return artist.name; // No comma for the last artist
        } else {
            return artist.name + ", "; // Include comma for all other artists
        }
    }).join("");

    return (
        <div className={styles["card-container"]}>
            <div className={styles["image-container"]}>
                <img
                    alt={""}
                    src={event.artists[0].image ? event.artists[0].image : noImage}
                    className={styles["image"]}
                />
                <div className={styles["description"]}>
                    <div>
                        <div
                            onClick={(event) => event.stopPropagation()} /* makes this div un-clickable for aesthetic reasons */
                            className={styles["default-cursor"]}
                        >
                            <h5 className={styles["event-artists"]}>{eventArtists}</h5>
                            <div>
                                <h6
                                    className={styles["city-text"]}
                                    onClick={() => navigate(`/city/${city.id}/${stringFormatting.toSlug(`${city.name}, ${city.zoneCode ? city.zoneCode : city.countryCode}`)}`)}
                                >{`${city.name}, ${city.zoneCode ? city.zoneCode : city.countryCode}`}</h6>
                                <h6
                                    className={styles["venue-text"]}
                                    onClick={() => navigate(`/venue/${event.venue.id}/${stringFormatting.toSlug(event.venue.name)}`)}
                                >{event.venue.name}</h6>
                            </div>

                            {/*<h6 className="gray11">{formatTimestamp(event.startTime)}</h6>*/}
                            <div
                                onClick={(event) => event.stopPropagation()} /* makes this div un-clickable for aesthetic reasons */
                                className={styles["default-cursor"]}
                            >
                                <h6 className={styles["similar-text"]}>Similar to:</h6>
                                <div className={styles["similar-artist-container"]}>
                                    {event.similar.slice(0, 3).map((similarArtist, index) => {
                                        return (
                                            <Tooltip title={similarArtist.name} key={index} placement="bottom" arrow>
                                                <img
                                                    alt=""
                                                    src={similarArtist.image ? similarArtist.image : noImage}
                                                    onClick={(event) => {
                                                        event.stopPropagation(); // Prevents the click event from triggering the parent 'onClick'
                                                        navigate(`/artist/${similarArtist.id}/${stringFormatting.toSlug(similarArtist.name)}`);
                                                    }}
                                                />
                                            </Tooltip>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles["end-container"]}>
                        <div className={styles["buttons-container"]}>
                            {event.ticketUrl && (
                                <div
                                    className={styles["tickets-icon-container"]}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        window.open(event.ticketUrl, "_blank")
                                    }}
                                >
                                    <img
                                        className={styles["icon"]}
                                        src="https://localify-cdn.s3.amazonaws.com/assets/web_assets/tickets_icon.png"
                                        alt=""
                                    />
                                </div>
                            )}
                            {event.artists[0].spotifyTopTrackPreview ? (
                                <>
                                    <audio ref={audioRef}>
                                        <source // "https://p.scdn.co/mp3-preview/ca7bdcb691fb64a5af8a3253b89356390664fcf1?cid=3fc5f299651443559142458908c467f8"
                                            src={event.artists[0].spotifyTopTrackPreview}
                                            type="audio/mpeg"/>
                                        Your browser does not support the audio element.
                                    </audio>
                                    <div className={styles['play-button-container']} onClick={(e) => {
                                        e.stopPropagation();
                                        toggleAudio();
                                    }}>
                                        {isPlaying ? (
                                            <PauseCircleOutline className={styles['icon']}/>
                                        ) : (
                                            <PlayCircleOutline className={styles['icon']}/>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <Tooltip title={"Audio Unavailable"} placement="top" arrow>
                                    <div
                                        className={`${styles['play-button-container']} ${styles['disabled']}`}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <PlayCircleOutline className={styles['icon']}/>
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                        <div
                            title={`${Math.round(event.percentMatch * 100)}% Match`}
                            className={styles["default-cursor"]}
                            onClick={(event) => event.stopPropagation()}
                        >
                            <h6 className={styles["text-center"]}>% Match</h6>
                            <Box sx={{
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <CircularProgress variant="determinate" value={event.percentMatch * 100} style={{
                                    color: "#E84865",
                                    width: '75%',
                                    height: '75%',
                                }}/>
                                <Box
                                    sx={{
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        position: 'absolute',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%', // Full width
                                        height: '100%', // Full height
                                    }}
                                >
                                    <Typography
                                        variant="caption"
                                        component="div"
                                        className={styles["white-text"]}
                                    >{`${Math.round(event.percentMatch * 100)}`}</Typography>
                                </Box>
                            </Box>
                        </div>
                    </div>
                </div>
                <div className={styles["date-card"]}>
                    <h6>{dateCard.day}</h6>
                    <h4>{dateCard.month} {dateCard.dayOfMonth}</h4>
                    <h6>{dateCard.time}</h6>
                </div>
            </div>
            <BookmarkButton
                initialIsFavorite={event.isFavorite}
                type={FavoriteTypes.events}
                id={event.id}
                handleError={handleError}
            />
        </div>
    )
}

export default EventRecommendationCard;
