import React, {useEffect, useRef, useState} from 'react';
import ActivityIndicator from "../components/ActivityIndicator";
import * as apiService from "../backend/apiService";
import {
    BasicArtistResponse,
    CityResponse,
    ComplexArtistResponse,
    EventResponse,
    FavoriteTypes
} from "../backend/apiService";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import globalColors from "../style/globalColors";
import BasicArtistCard from "../components/BasicArtistCard";
import ListOfCitiesModal from "../components/ListOfCitiesModal";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import EventListCard from "../components/EventListCard";
import AddCityToArtistModal from "../components/AddCityToArtistModal";
import PageHeader, {HeaderTypes} from "../components/PageHeader";
import ErrorModal from "../components/ErrorModal";
import * as stringFormatting from "../utils/StringFormatting";
import styles from "../style/pages/DetailPages.module.scss";
import BookmarkButton from "../components/BookmarkButton";
import {userHasToken} from "../backend/loginApi";
import {Add, AddCircleOutline, PlayCircleOutline} from "@mui/icons-material";
import {PauseCircleOutline} from "@mui/icons-material";
import FeedbackFormButton from "../components/FeedbackFormButton";

const ArtistDetail = () => {
    const audioRef = useRef<HTMLAudioElement>(null);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);

    const navigate = useNavigate();
    let { id, slug } = useParams();
    let [searchParams] = useSearchParams();

    const [loading, setLoading] = useState<boolean>(true);
    const [showEditCitiesModal, setShowEditCitiesModal] = useState<boolean>(false);
    const [showAddArtistCityModal, setShowAddArtistCityModal] = useState<boolean>(false);
    const [errorModalMessage, setErrorModalMessage] = useState<string | undefined>();
    const [artist, setArtist] = useState<ComplexArtistResponse | undefined>(undefined);
    const [genresString, setGenresString] = useState<string>("");
    const [similarArtists, setSimilarArtists] = useState<BasicArtistResponse[] | undefined>(undefined);
    const [cities, setCities] = useState<CityResponse[] | undefined>(undefined);
    const [nearbyEvents, setNearbyEvents] = useState<EventResponse[] | undefined>(undefined);
    const [otherEvents, setOtherEvents] = useState<EventResponse[] | undefined>(undefined);
    const [userCurrentCity, setUserCurrentCity] = useState<CityResponse | undefined>(undefined);

    const noImage = 'https://localify-cdn.s3.amazonaws.com/assets/web_assets/NoImage.jpeg';

    const toggleAudio = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    useEffect(() => {
        // Handle back button logic
        const handleBackButton = () => {
            const currentPath = window.location.pathname;
            const pathRegex = /^\/artist\/[^\/]+\/[^\/]+$/;

            if (pathRegex.test(currentPath)) {
                if (id && slug) {
                    changeArtist(id, slug);
                }
            }
        };

        window.addEventListener('popstate', handleBackButton);

        // Initial data fetch
        if (id) {
            getArtistInfo(id).then(() => {
                if (searchParams.get("open-city-modal")) {
                    setShowAddArtistCityModal(true);
                }
            });
        } else {
            setErrorModalMessage("We couldn't find the artist you're lookin")
        }

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [id, slug]);

    // Function to fetch artist information
    const getArtistInfo = async (id: string | undefined) => {
        if (id) {
            try {
                setLoading(true);
                const [artistInfo, cityResponse] = await Promise.all([
                    apiService.fetchArtist(id),
                    apiService.fetchCitiesForArtist(id),
                ]);

                setArtist(artistInfo);
                setCities(cityResponse);

                const genresString = artistInfo.genres.map(genre => `#${genre.name}`).join(' ');
                setGenresString(genresString);
                setSimilarArtists(artistInfo.similarArtists);
                setErrorModalMessage(undefined); // Gets rid of error modal if it's currently up
                const eventResponse = await apiService.fetchEventsForArtist(id);
                setNearbyEvents(eventResponse.nearbyEvents);
                setOtherEvents(eventResponse.otherEvents);
                setUserCurrentCity(eventResponse.city);

            } catch (error) {
                console.log("Artist not found: " + error);
                setErrorModalMessage("The artist you're looking for couldn't be found.")
            } finally {
                setLoading(false);
            }
        } else {
            setErrorModalMessage("The artist you're looking for couldn't be found.")
            setLoading(false);
        }
    };

    // Function to change artist when the id in the URL changes (example: searching for an artist while on artist detail page)
    const changeArtist = (id: string, name: string) => {
        navigate(`/artist/${id}/${stringFormatting.toSlug(name)}`);
        getArtistInfo(id).then();
    };

    // Render general section with artist name and genres
    const generalSection = () => {
        if (artist) {
            return (
                <div className={styles["main-section"]}>
                    <div className={styles["section-header"]}>
                        <h1 className={styles["no-margin"]}>{artist.name}</h1>
                    </div>
                    <h5 className={styles["no-margin"]}>{genresString}</h5>
                </div>
            );
        }
    };

    // Render similar artists section
    const similarArtistsSection = () => {
        if (similarArtists === undefined) {
            return (
                <div className={styles["main-section"]}>
                    <div className={styles["section-header"]}>
                        <h4 className={styles["no-margin"]}>Similar Artists</h4>
                    </div>
                    <div style={{ padding: "10px 0px 5px 0px" }}>
                        <ActivityIndicator width={"20px"} height={"20px"} />
                    </div>
                </div>
            );
        } else if (similarArtists?.length === 0) {
            return (
                <div className={styles["main-section"]}>
                    <div className={styles["section-header"]}>
                        <h4 className={styles["no-margin"]}>Similar Artists</h4>
                    </div>
                    <h6 style={{ textAlign: "center", marginTop: 20, color: globalColors.grey11 }}>Artist has no similar artists.</h6>
                </div>
            );
        } else {
            return (
                <div className={styles["main-section"]}>
                    <div className={styles["section-header"]}>
                        <h4 className={styles["no-margin"]}>Similar Artists</h4>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 10 }}>
                        {similarArtists.map((similarArtist, index) => (
                            <div style={{ marginRight: "2%", cursor: "pointer" }} key={index}>
                                <BasicArtistCard
                                    id={similarArtist.id}
                                    name={similarArtist.name}
                                    onClick={changeArtist}
                                    image={similarArtist.image}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
    };

    // Render cities section
    const citiesSection = () => {
        if (cities === undefined) {
            return (
                <div className={styles["main-section"]}>
                    <div className={styles["section-header"]}>
                        <h4 className={styles["no-margin"]}>Local Cities</h4>
                    </div>
                    <div style={{ padding: "10px 0px 5px 0px" }}>
                        <ActivityIndicator width={"20px"} height={"20px"} />
                    </div>
                </div>
            );
        } else if (cities.length === 0) {
            return (
                <div className={styles["main-section"]}>
                    <div className={styles["section-header"]}>
                        <h4 className={styles["no-margin"]}>Local Cities</h4>
                    </div>
                    <div>
                        <h6 style={{
                            textAlign: "center",
                            marginTop: 20,
                            color: globalColors.grey11
                        }}>Artist has no cities.</h6>
                    </div>
                    <div
                        className={styles["no-artist-city-button-container"]}
                        onClick={() => setShowAddArtistCityModal(true)}
                    >
                        <div className={styles["no-artist-city-button"]}>
                            <Add className={styles["icon"]}/>
                            <h6>Suggest a City</h6>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={styles["main-section"]}>
                    <div className={styles["section-header"]}>
                        <h4 className={styles["no-margin"]}>Local Cities</h4>
                        <div />
                    </div>
                    <div>
                        {cities.map((city, index) => (
                            <div
                                className={styles["city"]}
                                key={index}
                                onClick={() => navigate(`/city/${city.id}/${stringFormatting.toSlug(city.name + " " + city.zoneCode)}`)}
                            >
                                <LocationCityIcon style={{marginRight: 10, color: globalColors.white}}/>
                                <h5 className={styles["no-margin"]}>{stringFormatting.toLocationLabel(city.name, city.zoneCode, city.countryCode)}</h5>
                            </div>
                        ))}
                        <div
                            className={styles["suggest-city"]}
                            onClick={() => setShowAddArtistCityModal(true)}
                        >
                            <AddCircleOutline className={styles["icon"]} style={{marginRight: 10, color: globalColors.white}}/>
                            <h5 className={styles["no-margin"]}>Suggest a new city</h5>
                        </div>
                    </div>
                </div>
            );
        }
    };

    // Render events section
    const eventsSection = () => {
        if (nearbyEvents === undefined && otherEvents === undefined) {
            return (
                <div className={styles["main-section"]}>
                    <div className={styles["section-header"]}>
                        <h4 className={styles["no-margin"]}>Upcoming Events</h4>
                    </div>
                    <div style={{padding: "10px 0px 5px 0px"}}>
                        <ActivityIndicator width={"20px"} height={"20px"}/>
                    </div>
                </div>
            );
        }

        else if (nearbyEvents?.length === 0 && otherEvents?.length === 0) {
            return (
                <div className={styles["main-section"]}>
                    <div className={styles["section-header"]}>
                        <h4 className={styles["no-margin"]}>Upcoming Events</h4>
                    </div>
                    <div>
                        <h6 style={{
                            textAlign: "center",
                            marginTop: 20,
                            color: globalColors.grey11
                        }}>Artist has no events.</h6>
                    </div>
                </div>
            );
        }

        else if (nearbyEvents?.length === 0 && otherEvents !== undefined && otherEvents.length > 0) {
            return (
                <div className={styles["main-section"]}>
                    <div className={styles["section-header"]}>
                        <h4 className={styles["no-margin"]}>Upcoming Events</h4>
                    </div>
                    <div>
                        {otherEvents.map((event, index) => (
                            <div
                                key={index}
                                onClick={() => navigate(`/event/${event.id}/${stringFormatting.toSlug(event.name)}`)}
                            >
                                <EventListCard event={event} displayCity={true}/>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }

        else if (nearbyEvents !== undefined && nearbyEvents?.length > 0 && userCurrentCity) {
            return (
                <div className={styles["event-lists-container"]}>
                    <div className={styles["main-section"]}>
                        <div className={styles["section-header"]}>
                            <h4 className={styles["no-margin"]}>
                                {`Upcoming Events near ${userCurrentCity.name}, ${userCurrentCity.zoneCode || userCurrentCity.countryCode}`}
                            </h4>
                        </div>
                        <div>
                            {nearbyEvents.map((event, index) => (
                                <div
                                    key={index}
                                    onClick={() => navigate(`/event/${event.id}/${stringFormatting.toSlug(event.name)}`)}
                                >
                                    <EventListCard event={event} displayCity={true}/>
                                </div>
                            ))}
                        </div>
                    </div>
                    {otherEvents && (
                        <div className={styles["main-section"]}>
                            <div className={styles["section-header"]}>
                                <h4 className={styles["no-margin"]}>Other Events</h4>
                            </div>
                            <div>
                                {otherEvents.map((event, index) => (
                                    <div
                                        key={index}
                                        onClick={() => navigate(`/event/${event.id}/${stringFormatting.toSlug(event.name)}`)}
                                    >
                                        <EventListCard event={event} displayCity={true}/>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            );
        }
    };

    return (
        <div className="App-body">
            <FeedbackFormButton/>
            <PageHeader
                type={HeaderTypes.HOME}
            />
            {loading ?
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}>
                    <ActivityIndicator/>
                </div> :
                (artist &&
                    <div style={{flex: 1, marginTop: 60, marginBottom: 100}}>
                        <div className={styles["container"]}>
                            <div className={styles["side"]}
                                 style={{flexDirection: "column", justifyContent: "start", marginTop: 40}}>
                                <div style={{position: "relative", width: "80%"}}>
                                    <img alt="" className={styles["detail-image"]}
                                         src={artist.image ? artist.image : noImage}/>
                                    <BookmarkButton
                                        initialIsFavorite={artist.isFavorite}
                                        type={FavoriteTypes.artists}
                                        id={artist.id}
                                        handleError={() => setErrorModalMessage("Failed to favorite")}
                                    />
                                </div>
                                <div className={styles["buttons-under-image"]}>
                                    <div
                                        className={styles["spotify-button-under-image"]}
                                        onClick={() => window.open(`https://open.spotify.com/artist/${artist.spotifyId}`, '_blank')}
                                    >
                                        <img
                                            src="https://localify-cdn.s3.amazonaws.com/assets/web_assets/Spotify_Primary_Logo_RGB_Black.png"
                                            alt="Spotify Brand Logo"
                                            className={styles["icon"]}
                                        />
                                        <h6>View Spotify Profile</h6>
                                    </div>
                                    {artist.topSongPreview ? (
                                        <div>
                                            <audio ref={audioRef}>
                                                <source
                                                    src={artist.topSongPreview}
                                                    type="audio/mpeg"/>
                                                Your browser does not support the audio element.
                                            </audio>
                                            <div>
                                                <div className={styles['button-under-image']}
                                                     onClick={toggleAudio}>
                                                    {isPlaying ? (
                                                        <PauseCircleOutline className={styles['icon']}/>
                                                    ) : (
                                                        <PlayCircleOutline className={styles['icon']}/>
                                                    )}
                                                    <h6>Artist Song Preview</h6>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={`${styles['button-under-image']} ${styles["disabled"]}`}>
                                            <PlayCircleOutline className={styles['icon']}/>
                                            <h6>Artist Song Preview Unavailable</h6>
                                        </div>
                                    )}
                                    {
                                        artist.isSeed ?
                                            (<div
                                                className={styles['button-under-image']}
                                                onClick={() => window.location.href = '/saved/familiar-artists'}
                                            >
                                                <h6>Edit Familiar Artists</h6>
                                            </div>)
                                            : (<></>)
                                    }
                                </div>
                            </div>
                            <div className={styles["main"]}>
                                <div className={styles["show-over-1250"]}>{generalSection()}</div>
                                <div className={styles["show-over-1250"]}>{similarArtistsSection()}</div>
                                <div className={styles["show-over-1250"]}>{citiesSection()}</div>
                                <div className={styles["show-over-1250"]}>{eventsSection()}</div>
                            </div>
                            <div style={{flex: 1, width: "100%"}}>
                                <div className={styles["show-under-1249"]}>{generalSection()}</div>
                                <div className={styles["show-under-1249"]}>{similarArtistsSection()}</div>
                                <div className={styles["show-under-1249"]}>{citiesSection()}</div>
                                <div className={styles["show-under-1249"]}>{eventsSection()}</div>
                            </div>
                        </div>
                    </div>
                )
            }
            {showEditCitiesModal &&
                <ListOfCitiesModal
                    onClose={() => {
                        setShowEditCitiesModal(false);
                    }}
                />
            }
            {(showAddArtistCityModal && artist !== undefined) &&
                <AddCityToArtistModal onClose={() => setShowAddArtistCityModal(false)} artist={artist}/>
            }
            {errorModalMessage &&
                <ErrorModal
                    errorMessage={errorModalMessage}
                    onTryAgain={() => getArtistInfo(id)}
                    loading={loading}
                    onClose={() => navigate("/")}
                    onCloseMessage={"Back to home"}
                />}
        </div>
    )
}

export default ArtistDetail;
