import React, {useCallback, useEffect, useState} from "react";
import * as apiService from "../backend/apiService";
import {ArtistResponse, EventResponse, FavoriteTypes, UserCityResponse, UserDetails} from "../backend/apiService";
import globalColors from "../style/globalColors";
import styles from '../style/pages/Settings.module.scss';
import {useNavigate} from "react-router-dom";
import * as stringFormatting from "../utils/StringFormatting";
import * as Store from "../backend/storage";

// Importing icons
import LocationCityIcon from '@mui/icons-material/LocationCity';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';

// Importing components
import ActivityIndicator from "../components/ActivityIndicator";
import ListOfCitiesModal from "../components/ListOfCitiesModal";
import ErrorModal from "../components/ErrorModal";
import PageHeader, {HeaderTypes, PageTypes} from "../components/PageHeader";
import {frontendEndpoint} from "../backend/axiosInstance";
import SpotifyModal from "../components/SpotifyModal";
import EmailVerificationModal from "../components/EmailVerificationModal";
import * as loginApi from "../backend/loginApi";
import {LoginStrategy, MergeableAccount} from "../backend/loginApi";
import EmailEnterModal from "../components/EmailEnterModal";
import MergeAccountsModal from "../components/MergeAccountsModal";
import EditProfileModal from "../components/EditProfileModal";
import FeedbackFormButton from "../components/FeedbackFormButton";
import {useUser} from "../hooks/UserContext";

enum ErrorTypes {
    FAILED_TO_FETCH_USER_DETAILS = "Failed to fetch user details",
    FAILED_TO_FETCH_USER_CITIES = "Failed to fetch user cities",
    FAILED_TO_FETCH_USER_SEEDS = "Failed to fetch user seeds",
    FAILED_TO_FETCH_USER_BOOKMARKED_ARTISTS = "Failed to fetch user bookmarked artists",
    FAILED_TO_FETCH_USER_BOOKMARKED_EVENTS = "Failed to fetch user bookmarked events",
    FAILED_TO_FAVORITE = "Failed to favor artist, event, or venue"
}

const Settings = () => {
    const navigate = useNavigate();
    const [userCities, setUserCities] = useState<UserCityResponse | undefined>(undefined);
    const [showCitiesModal, setShowCitiesModal] = useState<boolean>(false);
    const [showSpotifyModal, setShowSpotifyModal] = useState<boolean>(false);
    const [errorType, setErrorType] = useState<ErrorTypes | undefined>();

    const [showEmailEnterModal, setShowEmailEnterModal] = useState<boolean>(false);
    const [emailNonce, setEmailNonce] = useState<string>("");
    const [emailCode, setEmailCode] = useState<string>("");
    const [numTries, setNumTries] = useState<number>(0);
    const [verifyButtonActive, setVerifyButtonActive] = useState<boolean>(false);
    const [emailText, setEmailText] = useState<string>("");

    const [mergeableAccount, setMergeableAccount] = useState<MergeableAccount | undefined>(undefined);
    const[showEditProfileModal, setShowEditProfileModal] = useState<boolean>(false);
    const { userInfo, setUserInfo } = useUser();

    const noImage = 'https://localify-cdn.s3.amazonaws.com/assets/web_assets/NoImage.jpeg';

    useEffect(() => {
        fetchUserData().then();
    }, []);

    useEffect(() => {
        if (!showCitiesModal) {
            fetchUserCities().then(() => null);
        }
    }, [showCitiesModal]);

    const fetchUserDetails = () => {
        apiService.fetchUserDetails().then(details => setUserInfo(details));
    }

    const fetchUserData = useCallback(async () => {
        try {
            fetchUserDetails();
            await fetchUserCities();
        } catch (error) {
            setErrorType(ErrorTypes.FAILED_TO_FETCH_USER_DETAILS);
        }
    }, []);

    const fetchUserCities = useCallback(async () => {
        try {
            const userCityDetails = await apiService.fetchUserCities();
            setUserCities(userCityDetails);
        } catch (error) {
            setErrorType(ErrorTypes.FAILED_TO_FETCH_USER_CITIES);
        }
    }, []);

    const handleLogout = () => {
        Store.clearAuthToken();
        navigate('/login');
    };

    const fetchErrorMessage = () => {
        if (errorType === ErrorTypes.FAILED_TO_FETCH_USER_DETAILS)
            return "Failed to fetch user details.";
        else if (errorType === ErrorTypes.FAILED_TO_FETCH_USER_CITIES)
            return "Failed to fetch user cities.";
        else if (errorType === ErrorTypes.FAILED_TO_FAVORITE)
            return "Failed to favorite artist, event, or venue."
    }

    const handleRetry = () => {
        if (errorType === ErrorTypes.FAILED_TO_FETCH_USER_DETAILS)
            fetchUserData().then(() => setErrorType(undefined));
        else if (errorType === ErrorTypes.FAILED_TO_FETCH_USER_CITIES)
            fetchUserCities().then(() => setErrorType(undefined));
    }

    const connectWithSpotify = async () => {
        const randomstring = require("randomstring")

        let random = randomstring.generate({ length: 32, charset: "alphabetic" });
        await Store.storeSpotifySecret(random);
        const inputString = random + '_localify-auth';

        // Use the Web Crypto API to generate a SHA-256 hash
        const encoder = new TextEncoder();
        const data = encoder.encode(inputString);
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const sha256Hash = hashArray.map(byte => String.fromCharCode(byte)).join('');
        const base64Hash = btoa(sha256Hash);

        // Make the base64-encoded hash URL-friendly by replacing characters
        const urlFriendlyHash = base64Hash.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

        // Replace the following line with your actual Spotify URL
        await apiService.connectExistingUserSpotify(`?redirect=${frontendEndpoint}spotify-link&state=${urlFriendlyHash}=`).then((url) => {
            console.log(url)
            window.location.href = url
        })
    }

    const submitEmailVerification = (value: string) => {
        setVerifyButtonActive(true);
        loginApi.handlePinEnterLink(emailNonce, value).then(async authMergeable => {
            const respToken = authMergeable.authCredentials;
            const mergeable = authMergeable.mergeableAccount;
            if (respToken != null) {
                if (respToken.token) {
                    await Store.storeTokenData(respToken);
                    Store.storeUserLoginStrategy(LoginStrategy.EMAIL);
                    closeEmailModal();
                    const nextPage = '/email-link';
                    navigate(nextPage);
                } else {
                    throw new Error("Token is null")
                }
                setVerifyButtonActive(false);
            } else if (mergeable != null) {
                setMergeableAccount(mergeable);
            }

        }).catch(error => {
            setEmailCode("");
            const newTries = numTries + 1;
            setNumTries(newTries);
            setVerifyButtonActive(false);
        })
    }

    const submitEmailVerificationChangeEmail = (value: string) => {
        setVerifyButtonActive(true);
        loginApi.handlePinEnterChangeEmail(emailNonce, value).then(async authMergeable => {
            const respToken = authMergeable.authCredentials;
            const mergeable = authMergeable.mergeableAccount;
            if (respToken != null) {
                if (respToken.token) {
                    await Store.storeTokenData(respToken);
                    closeEmailModal();
                    const nextPage = '/email-link';
                    navigate(nextPage);
                } else {
                    throw new Error("Token is null")
                }
                setVerifyButtonActive(false);
            } else if (mergeable != null) {
                setMergeableAccount(mergeable);
            }

        }).catch(error => {
            console.log("You failed: " + error)
            setEmailCode("");
            const newTries = numTries + 1;
            setNumTries(newTries);
            setVerifyButtonActive(false);
        })
    }

    const handleAcceptMerge = (id: string | undefined, authToken: string | undefined, changeEmail: boolean) => {
        loginApi.handleAccountMerge(id, authToken, changeEmail).then(async creds => {
            if (creds.token) {
                await Store.storeTokenData(creds);
                Store.storeUserLoginStrategy(LoginStrategy.EMAIL);
                closeEmailModal();
                const nextPage = '/email-link';
                navigate(nextPage);
            } else {
                throw new Error("Token is null")
            }
            setVerifyButtonActive(false);
        })
    }

    const closeEmailModal = () => {
        setEmailCode("");
        setNumTries(0);
        setEmailText("");
        setEmailNonce("");
        setShowEmailEnterModal(false);
        setMergeableAccount(undefined);
    }

    const generalSection = (user: UserDetails) => (
        <div className={styles['main-section']}>
            <div className={styles['section-header']}>
                <h1>{user.name}</h1>
                <h6
                    className={styles['edit-profile-button']}
                    onClick={() => setShowEditProfileModal(true)}
                >Edit Profile</h6>
            </div>

            <h5 className='grey12'>{user.email ? user.email : "No Email Given"} {user.email ?
                <EditIcon
                    className={styles['button']}
                    style={{ fontSize: '24px' }}
                    onClick={() => setShowEmailEnterModal(true)}/> : <></>} </h5>
            <h6 className="grey12">{userInfo && `Member since: ${stringFormatting.formatMonthDayYear(userInfo.accountCreationDate)}`}</h6>
            <div
                className={`${styles['new-login-strategy']} spotify-green-border ${user.spotifyConnected && 'spotify-green-background'}`}
            >
                <h5 style={{cursor: user.spotifyConnected ? "default" : "pointer"}}>
                    {user.spotifyConnected ? `Connected with Spotify` : `Connect with Spotify`}
                </h5>
                {user.spotifyConnected ? <CheckIcon style={{fontSize: 20}}/> :
                    <h6
                        className="spotify-green-background"
                        onClick={() => setShowSpotifyModal(true)}
                    >Connect</h6>}
            </div>
            <div
                className={`${styles['new-login-strategy']} primary-border ${(user.appleConnected || user.emailConnected) && 'primary-background'}`}
            >
                <h5 style={{cursor: user.appleConnected || user.emailConnected ? "default" : "pointer"}}>
                    {user.appleConnected || user.emailConnected ? `Connected with Email` : `Connect with Email`}
                </h5>
                {user.appleConnected || user.emailConnected ? <CheckIcon style={{fontSize: 20}}/> :
                    <h6 className="primary-background"
                        onClick={() => setShowEmailEnterModal(true)}
                    >
                        Connect
                    </h6>
                }
            </div>

        </div>
    )

    const citiesSection = () => (
        <div className={styles['main-section']}>
            <div className={styles['section-header']}>
                <h4>Your Cities</h4>
                <div />
            </div>
            {userCities === undefined ? <ActivityIndicator/> :
                <div>
                    <div
                        className={`${styles['city']} ${styles["primary-background"]}`}
                        onClick={() => {
                            setShowCitiesModal(true)
                        }}
                    >
                        <LocationCityIcon className={styles['city-icon']}/>
                        <h5>{`${userCities.current.name}, ${userCities.current.zoneCode}`}</h5>
                    </div>
                    {userCities.others?.slice(0, 2).map((city, index) => (
                        <div
                            key={index}
                            className={`${styles['city']}`}
                            onClick={() => {
                                setShowCitiesModal(true);
                            }}
                        >
                            <LocationCityIcon className={styles['city-icon']}/>
                            <h5>{`${city.name}, ${city.zoneCode}`}</h5>
                        </div>
                    ))}
                    <div
                        className={`${styles['edit-cities']}`}
                        onClick={() => {
                            setShowCitiesModal(true);
                        }}
                    >
                        <EditIcon className={`${styles['city-icon']} grey12`}/>
                        <h6 className="gray12">Edit Cities</h6>
                    </div>
                </div>
            }
        </div>
    )

    return (
        <div className="App-body">
            {errorType &&
                <ErrorModal
                    errorMessage={fetchErrorMessage()}
                    onTryAgain={errorType !== ErrorTypes.FAILED_TO_FAVORITE ? handleRetry : undefined}
                    onClose={() => {
                        if (errorType !== ErrorTypes.FAILED_TO_FAVORITE)
                            navigate('/')
                        setErrorType(undefined);
                    }}
                    onCloseMessage={errorType === ErrorTypes.FAILED_TO_FAVORITE ? "Close" : "Back to Home"}
                />}
            <PageHeader
                type={HeaderTypes.HOME}
            />
            <FeedbackFormButton />

            {!userInfo ? <ActivityIndicator/> :
                <div className={styles['main-container']}>
                    <div className="container">
                        <div className={styles['side']}>
                            <img
                                src={
                                    userInfo.spotifyProfileImage ||
                                    userInfo.profileImage ||
                                    noImage}
                                alt=""
                                className={styles['profile-image']}/>
                        </div>
                        <div className={styles['main']}>
                            <div className={styles['display-over-1250']}>{generalSection(userInfo)}</div>
                            <div className={styles['display-over-1250']}>{citiesSection()}</div>
                        </div>

                        <div className={styles['area-under-image']}>
                            <div className={styles['main']}>
                                <div className={styles['display-under-1251']}>{generalSection(userInfo)}</div>
                                <div className={styles['display-under-1251']}>{citiesSection()}</div>
                            </div>
                        </div>
                    </div>

                    <div
                        className={styles['logout-button-container']}
                        onClick={() => handleLogout()}
                    >
                        <h5 className={styles['logout-button']}>Log Out</h5>
                    </div>
                </div>
            }

            {/* ****MODALS BELOW**** */}
            {showCitiesModal &&
                <ListOfCitiesModal
                    initialCities={userCities?.others || []}
                    initialCurrentCity={userCities?.current}
                    onClose={() => {
                        setShowCitiesModal(false);
                    }}
                />
            }
            {showSpotifyModal &&
                <SpotifyModal
                    onClose={() => setShowSpotifyModal(false)}
                    onConnect={connectWithSpotify}
                />
            }
            {showEmailEnterModal && (
                <EmailEnterModal
                    onClose={() => setShowEmailEnterModal(false)}
                    onError={() => null}
                    onSubmit={(outEmailText, outEmailNonce) => {
                        setEmailNonce(outEmailNonce);
                        setEmailText(outEmailText);
                    }}
                />
            )}

            {emailNonce && !userInfo?.email && (
                <div
                    style={{
                        position: "fixed", // Fixed position for modal
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 1000, // Adjust the z-index as needed
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
                    }}
                >
                    <div
                        style={{
                            width: "60%", // You can adjust the width as needed
                            backgroundColor: globalColors.dark,
                            padding: "20px", // Add padding for content
                            borderRadius: "10px",
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)", // Optional shadow
                            color: globalColors.white,
                            justifyItems: "center",
                            alignItems: "center"
                        }}
                    >
                        <EmailVerificationModal
                            value={emailCode}
                            setValue={setEmailCode}
                            email={emailText}
                            nonce={emailNonce}
                            onComplete={submitEmailVerification}
                            numTries={numTries}
                            verifyButtonActive={verifyButtonActive}
                            onClose={closeEmailModal}
                        />
                    </div>
                </div>
            )}

            {emailNonce && userInfo?.email && (
                <div
                    style={{
                        position: "fixed", // Fixed position for modal
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 1000, // Adjust the z-index as needed
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
                    }}
                >
                    <div
                        style={{
                            width: "60%", // You can adjust the width as needed
                            backgroundColor: globalColors.dark,
                            padding: "20px", // Add padding for content
                            borderRadius: "10px",
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)", // Optional shadow
                            color: globalColors.white,
                            justifyItems: "center",
                            alignItems: "center"
                        }}
                    >
                        <EmailVerificationModal
                            value={emailCode}
                            setValue={setEmailCode}
                            email={emailText}
                            nonce={emailNonce}
                            onComplete={submitEmailVerificationChangeEmail}
                            numTries={numTries}
                            verifyButtonActive={verifyButtonActive}
                            onClose={closeEmailModal}
                        />
                    </div>
                </div>
            )}

            {mergeableAccount && (
                <MergeAccountsModal
                    onClose={closeEmailModal}
                    mergeableAccount={mergeableAccount}
                    onSubmit={handleAcceptMerge}
                    fromSpotify={false}
                    email={userInfo?.email}
                />
            )}

            {(showEditProfileModal && userInfo) && (
                <EditProfileModal
                    onClose={() => setShowEditProfileModal(false)}
                    onSubmit={userDetails => {
                        setUserInfo(userDetails);
                        setShowEditProfileModal(false);
                    }}
                    username={userInfo.name}
                    emailOptIn={userInfo.emailOptIn}
                    email={userInfo.email || ""}
                />
            )}
        </div>
    )
}


export default Settings;
