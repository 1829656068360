import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "../style/pages/ChooseArtists.module.scss";
import chooseLocationStyles from "../style/pages/ChooseLocation.module.scss";

import PageHeader, { HeaderTypes } from "../components/PageHeader";
import OnboardingBreadcrumb, { OnboardingStages } from "../components/OnboardingBreadcrumb";

import * as apiService from "../backend/apiService";
import { ArtistResponse, TopGenresResponse } from "../backend/apiService";
import ActivityIndicator from "../components/ActivityIndicator";
import SearchArtistModal from "../components/SearchArtistModal";
import SearchIcon from "@mui/icons-material/Search";

const ChooseArtists: React.FC = () => {
    const noImage = 'https://localify-cdn.s3.amazonaws.com/assets/web_assets/NoImage.jpeg';

    const [currentOnboardingStage, setCurrentOnboardingStage] = useState<OnboardingStages>(OnboardingStages.GENRES);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [genres, setGenres] = useState<TopGenresResponse[] | undefined>(undefined);
    const [genresSelected, setGenresSelected] = useState<string[]>([]);
    const [artists, setArtists] = useState<ArtistResponse[]>([]);
    const [artistsSelected, setArtistsSelected] = useState<string[]>([]);
    const [searchedArtists, setSearchedArtists] = useState<ArtistResponse[]>([]);
    const [showSearchArtistsModal, setShowSearchArtistsModal] = useState<boolean>(false);
    const [allowProceed, setAllowProceed] = useState<boolean>(false);

    // Fetch genres from API on component mount
    const fetchGenres = () => {
        setLoading(true);
        apiService.fetchTopGenres()
            .then(response => setGenres(response))
            .catch(error => console.log(error))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        document.body.style.overflow = "auto";
        fetchGenres();
    }, []);

    // Handle selection of genre cards
    const handleGenreCardSelect = (genreId: string) => {
        setGenresSelected((prevSelectedGenres) => {
            if (prevSelectedGenres.includes(genreId)) {
                return prevSelectedGenres.filter((id) => id !== genreId);
            } else {
                return [...prevSelectedGenres, genreId];
            }
        });
    };

    // Toggle artist selection for styling
    const handleArtistCardSelect = (artistId: string) => {
        setArtistsSelected((prevSelectedArtists) => {
            if (prevSelectedArtists.includes(artistId)) {
                return prevSelectedArtists.filter((id) => id !== artistId);
            } else {
                return [...prevSelectedArtists, artistId];
            }
        });
    };

    // Update proceed button status based on the current stage
    useEffect(() => {
        if (currentOnboardingStage === OnboardingStages.GENRES) {
            setAllowProceed(genresSelected.length >= 3 && genresSelected.length <= 5);
        }
    }, [currentOnboardingStage, genresSelected]);

    useEffect(() => {
        if (currentOnboardingStage === OnboardingStages.ARTISTS) {
            setAllowProceed(artistsSelected.length >= 5);
        }
    }, [currentOnboardingStage, artistsSelected]);

    // Fetch artists for selected genres and update onboarding stage
    const handleGenresSubmit = () => {
        const selectedArtists = genresSelected.reduce<ArtistResponse[]>((allArtists, genreId) => {
            const genre = genres?.find((g) => g.id === genreId);
            if (genre) {
                const newArtists = genre.topArtists.filter(
                    (artist) => !allArtists.some((existingArtist) => existingArtist.id === artist.id)
                );
                return allArtists.concat(newArtists);
            }
            return allArtists;
        }, []);

        selectedArtists.sort(() => Math.random() - 0.5);
        setArtists(selectedArtists);

        setCurrentOnboardingStage(OnboardingStages.ARTISTS);
        setLoading(false);
        setAllowProceed(false);
    };

    // Submit selected artists and navigate to recommendations
    const handleArtistsSubmit = () => {
        apiService.putUserSeeds(artistsSelected).then(() => {
            navigate('/recommendations', { replace: true });
        });
    };

    // Handle proceed action based on current onboarding stage
    const handleProceeding = () => {
        if (allowProceed) {
            if (currentOnboardingStage === OnboardingStages.GENRES) {
                setLoading(true);
                handleGenresSubmit();
            } else if (currentOnboardingStage === OnboardingStages.ARTISTS) {
                handleArtistsSubmit();
            }
        }
    };

    const handleBackButton = () => {
        if (currentOnboardingStage === OnboardingStages.GENRES) {
            navigate("/onboarding/location");
        } else if (currentOnboardingStage === OnboardingStages.ARTISTS) {
            setCurrentOnboardingStage(OnboardingStages.GENRES);
            setArtistsSelected([]);
            setSearchedArtists([]);
        }
    };

    const continueButtonDisabled = () => {
        if (currentOnboardingStage === OnboardingStages.GENRES) {
            return genresSelected.length < 3 || genresSelected.length > 5;
        } else if (currentOnboardingStage === OnboardingStages.ARTISTS && artistsSelected.length >= 5) {
            return false;
        } else {
            return true;
        }
    };

    const continueButtonText = () => {
        if (currentOnboardingStage === OnboardingStages.GENRES) {
            if (genresSelected.length > 5) return `Too Many Genres Selected`;
            if (genresSelected.length < 3) return `${genresSelected.length}/3 Genres Selected`;
            else return `${genresSelected.length} Genres Selected`;
        }
        if (currentOnboardingStage === OnboardingStages.ARTISTS) {
            if (artistsSelected.length < 5) return `${artistsSelected.length}/5 Artists Selected`;
            else return `${artistsSelected.length} Artists Selected`;
        }
        return "Continue";
    };

    return (
        <div className={styles["App-body"]}>
            <PageHeader
                type={HeaderTypes.DEFAULT}
                backButtonString={currentOnboardingStage === OnboardingStages.GENRES ? "Choose Location" : "Select Genres"}
                backButtonFunction={handleBackButton}
                continueButtonFunction={handleProceeding}
                continueDisabled={continueButtonDisabled()}
                continueText={continueButtonText()}
            />
            <section className={styles["onboarding-header"]}>
                <div className={styles["header-text"]}>
                    <h1 className={styles["header-title"]}>
                        {currentOnboardingStage === OnboardingStages.GENRES
                            ? "What kinds of music do you like?"
                            : "Who are you currently listening to?"}
                    </h1>
                    {currentOnboardingStage === OnboardingStages.GENRES ? (
                        <p className={styles["header-subtitle"]}>
                            Choose at least 3 genres <span className={styles["max-num"]}>(max. 5)</span>
                        </p>
                    ) : (
                        <p className={styles["header-subtitle"]}>
                            Pick at least 5 artists. <span className={styles["max-num"]}>Add as many more as you like! </span>
                        </p>
                    )}
                </div>
                {showSearchArtistsModal && (
                    <SearchArtistModal
                        onArtistClick={(artistID, artistSlug, artist) => {
                            if (artist) {
                                // Add to `searchedArtists` and toggle selection
                                setSearchedArtists((prev) => {
                                    if (!prev.some((searched) => searched.id === artistID)) {
                                        return [artist, ...prev];
                                    }
                                    return prev;
                                });

                                setArtistsSelected((prevSelectedArtists) => {
                                    if (prevSelectedArtists.includes(artistID)) {
                                        return prevSelectedArtists.filter((id) => id !== artistID);
                                    } else {
                                        return [...prevSelectedArtists, artistID];
                                    }
                                });

                                setArtists((prev) => prev.filter((a) => a.id !== artistID));
                            }
                        }}
                        onClose={() => setShowSearchArtistsModal(false)}
                        selectedArtistsIDs={artistsSelected}
                    />
                )}
            </section>
            {loading ? (
                <div className={styles["activity-indicator-container"]}>
                    <ActivityIndicator />
                </div>
            ) : (
                !showSearchArtistsModal && (
                    <div className={styles["cards-container"]}>
                        {currentOnboardingStage === OnboardingStages.GENRES ? (
                            genres && genres.map((genre, i) => {
                                let artistList = genre.topArtists.slice(0, 3).map(
                                    (artist, index) => (index !== 0 ? ", " : "") + artist.name
                                );
                                return (
                                    <div
                                        key={i}
                                        onClick={() => handleGenreCardSelect(genre.id)}
                                        className={`${styles["genres-card"]} ${genresSelected.includes(genre.id) ? styles["active"] : ""}`}
                                    >
                                        <h3>{genre.name}</h3>
                                        <h6>{artistList}</h6>
                                    </div>
                                );
                            })
                        ) : (
                            <>
                                <div
                                    className={styles["search-card"]}
                                    onClick={() => setShowSearchArtistsModal(true)}
                                >
                                    <SearchIcon fontSize={"large"} className={styles["icon"]} />
                                    <h6>Search Artists</h6>
                                </div>
                                {searchedArtists.map((artist, index) => (
                                    <div
                                        key={index}
                                        className={`
                                            ${styles["artist-card"]} 
                                            ${artistsSelected.includes(artist.id) ? styles["selected"] : ""}
                                        `}
                                        onClick={() => handleArtistCardSelect(artist.id)}
                                    >
                                        <img src={artist.image} alt="" className={styles["artist-image"]} />
                                        <h6 className={styles["text-under-image"]}>{artist.name}</h6>
                                    </div>
                                ))}
                                {artists.map((artist, index) => (
                                    <div
                                        key={index}
                                        className={`
                                            ${styles["artist-card"]} 
                                            ${artistsSelected.includes(artist.id) ? styles["selected"] : ""}
                                        `}
                                        onClick={() => handleArtistCardSelect(artist.id)}
                                    >
                                        <img src={artist.image} alt="" className={styles["artist-image"]} />
                                        <h6 className={styles["text-under-image"]}>{artist.name}</h6>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                )
            )}
            <section className={`${chooseLocationStyles["breadcrumb-section"]} ${styles["breadcrumb-background"]}`}>
                <OnboardingBreadcrumb
                    onboardingStage={currentOnboardingStage}
                    goBackGenres={() => setCurrentOnboardingStage(OnboardingStages.GENRES)}
                    allowTravelArtists={genresSelected.length > 2}
                    handleArtistButtonClicked={handleProceeding}
                />
            </section>
        </div>
    );
};

export default ChooseArtists;
