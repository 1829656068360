import {Feedback} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import React from "react";

const FeedbackFormButton: React.FC = () => {

    return (
        <Tooltip title={"Submit Feedback"} placement={"left"} arrow>
            <div className={"bottom-right-icons"}>
                <div
                    className={"bottom-right-icon-container"}
                    onClick={() => window.open(window.location.origin + "/feedback-form", "_blank")}
                >
                    <Feedback className={"bottom-right-icon"} />
                </div>
            </div>
        </Tooltip>
    )
}

export default FeedbackFormButton;
