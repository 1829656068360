import React, {useState, useEffect, useRef} from 'react';
import globalColors from "../style/globalColors";
import * as apiService from "../backend/apiService";
import {ArtistResponse} from "../backend/apiService";
import "../style/components/components.scss";
import {useNavigate} from "react-router-dom";
import BasicArtistCard from "./BasicArtistCard";
import * as stringFormatting from "../utils/StringFormatting";

type Props = {
    onClose: () => void;
    onArtistClick?: (id: string, slug: string, artist?: ArtistResponse) => void;
    selectedArtistsIDs?: string[]; //For onboarding page
}

const SearchArtistModal: React.FC<Props> = ({ onClose, onArtistClick, selectedArtistsIDs }) => {
    const [text, setText] = useState<string>('');
    const [artists, setArtists] = useState<ArtistResponse[]>([]);
    const [noResults, setNoResults] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.add('no-scroll');
        inputRef.current?.focus();
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        setText(text);
        apiService.fetchSearchArtists(text, 6)
            .then(artists => {
                setArtists(artists);
                setNoResults(artists.length === 0 && text.length > 0);
            })
            .catch(error => console.log(error));
    };

    return (
        <div>
            {/* Overlay */}
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100vh',
                    backgroundColor: 'rgba(0, 0, 0, 0.9)',
                    zIndex: 9999,
                    pointerEvents: 'auto', // Ensure the overlay captures clicks
                }}
                onClick={onClose}
            />
            {/* Modal */}
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                maxHeight: '100vh',
                overflowY: 'auto',
                backgroundColor: globalColors.background,
                zIndex: 10000,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '20px',
                boxSizing: 'border-box',
                pointerEvents: 'auto', // Ensure the modal content captures clicks
            }}>
                {/* Input and Button */}
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%'
                }}>
                    <input
                        ref={inputRef}
                        className="header-search-artist"
                        type="text"
                        placeholder="Search artist"
                        value={text}
                        onChange={handleInputChange}
                        style={{
                            width: 'calc(100% - 120px)', // Adjust width to accommodate button
                            maxWidth: '600px',
                            padding: '10px',
                            borderRadius: '5px',
                            border: `1px solid ${globalColors.primary}`,
                            marginRight: '10px', // Space between input and button
                        }}
                    />
                    <button
                        onClick={onClose}
                        style={{
                            padding: "10px 20px",
                            borderRadius: "20px",
                            color: globalColors.white,
                            backgroundColor: globalColors.primary,
                            fontSize: "16px",
                            border: "none",
                            cursor: "pointer",
                        }}>
                        Close
                    </button>
                </div>
                {/* Artist Cards Grid */}
                <div style={{ width: '75%', margin: '0 auto'}}>
                    <div className="search-artist-card">
                        {noResults ? <h6>No artists found</h6> :
                            artists.map((artist, index) => (
                                <div
                                    key={index}
                                    onClick={() => {
                                        if (onArtistClick) {
                                            onArtistClick(artist.id, stringFormatting.toSlug(artist.name), artist);
                                        } else {
                                            navigate(`/artist/${artist.id}/${stringFormatting.toSlug(artist.name)}`);
                                        }
                                    }}
                                >
                                    <BasicArtistCard
                                        name={artist.name}
                                        id={artist.id}
                                        image={artist.image}
                                        selected={selectedArtistsIDs?.includes(artist.id)}
                                    />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchArtistModal;
