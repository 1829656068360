import React, {useState} from "react";
import styles from "../style/pages/Settings.module.scss";
import globalColors from "../style/globalColors";
import * as apiService from "../backend/apiService";
import ToggleSwitchWithLabel from "./ToggleSwitchWithLabel";
import {UserDetails} from "../backend/apiService";

type Props = {
    onClose: () => void;
    onSubmit: (userDetails: UserDetails) => void;
    username: string;
    emailOptIn: boolean;
    email: string;
}

const EditProfileModal: React.FC<Props> = ({
                                              onClose,
                                              onSubmit,
                                              username,
                                              emailOptIn,
                                              email
                                          }) => {

    const [currentUsername, setCurrentUsername] = useState<string>(username);
    const [currentEmailOptIn, setCurrentEmailOptIn]  = useState<boolean>(emailOptIn);
    const [showUsernameWarning, setShowUsernameWarning] = useState<boolean>(false);
    const[showEmailWarning, setShowEmailWarning] = useState<boolean>(false);

        const submitSettings = () => {
            if (!currentUsername || currentUsername.length < 3) {
                setShowUsernameWarning(true);
            }

            else if  (currentEmailOptIn && email === null){
                setShowEmailWarning(true);
            }
            else {
                setShowUsernameWarning(false);
                setShowEmailWarning(false)
                apiService.patchUserDetails({name: currentUsername, emailOptIn: currentEmailOptIn}).then
                ((userDetails) => {
                    setShowUsernameWarning(false);
                    setShowEmailWarning(false);
                    onSubmit(userDetails);
                }).catch((error) => {
                    console.error("Error updating settings", error);
                });
            }
        };

        return (
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 1000,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
            >
                <div
                    style={{
                        width: "30%", //
                        backgroundColor: globalColors.dark,
                        padding: "20px",
                        borderRadius: "10px",
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                        color: globalColors.white,
                        justifyItems: "center",
                        alignItems: "center"
                    }}

                >
                    <div>
                        <h4
                            style={{
                                height: 50
                            }}
                        >
                            Edit Profile
                        </h4>
                        <div style={{display: 'flex', alignItems: 'center', marginBottom: 20}}>
                        <span style={{
                            color: 'white',
                            marginRight: 10,
                            fontSize: 16
                        }}>Username:</span>
                            <input
                                type="text"
                                value={currentUsername}
                                style={{
                                    width: "96%",
                                    height: 50,
                                    borderRadius: 8,
                                    borderColor: 'white',
                                    borderWidth: 2,
                                    backgroundColor: "rgba(0, 0, 0, 0)",
                                    paddingLeft: 10,
                                    color: 'white',
                                    fontSize: 16
                                }}
                                onChange={(event) => setCurrentUsername(event.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter")
                                        submitSettings()
                                    if (e.key === "Escape")
                                        onClose();
                                }}
                            />
                        </div>
                        <ToggleSwitchWithLabel
                            label="Opt-in to Weekly Digest Emails?: "
                            onToggle={setCurrentEmailOptIn}
                            initialState={currentEmailOptIn}
                        />
                        <div className={styles['buttons']}>
                            <h6 onClick={onClose}>Cancel</h6>
                            <h6 onClick={() => submitSettings() }>Submit
                            </h6>
                        </div>
                        {showUsernameWarning &&
                            <h6 style={{fontSize: 12, marginTop: 8, marginBottom: 4, color: globalColors.primary}}>
                                Invalid username. Username must be at least 3 characters long.
                            </h6>
                        }

                        {showEmailWarning &&
                            <h6 style={{fontSize: 12, marginTop: 8, marginBottom: 4, color: globalColors.primary}}>
                                Accounts without linked emails cannot subscribe to digest emails.
                            </h6>
                        }
                    </div>
                </div>
            </div>
        )
};

export default EditProfileModal;
