import React from 'react';
import globalColors from "../style/globalColors";

const PageFooter: React.FC = () => {
    return (
        <div style={{
            width: '100%',
            backgroundColor: globalColors.lighterDark2,
            textAlign: 'center',
            padding: '20px 0',
        }}>
            <h6>Copyright © 2024 Localify. All rights reserved.</h6>
        </div>
    );
};

export default PageFooter;
