import React from 'react';
import PageHeader, { HeaderTypes } from "../components/PageHeader";
import styles from "../style/pages/AboutUs.module.scss";
import {toSlug} from "../utils/StringFormatting";
import teamMembers from "../assets/TeamMembers.json";
import lydiaImage from "../assets/Lydia_Image.png";
import {useNavigate} from "react-router-dom";

const AboutUs = () => {
    const navigate = useNavigate();

    return (
        <div className="App-body">
            <PageHeader type={HeaderTypes.LANDING}/>
            <div className={styles["main-container"]}>
                <div className={styles["general-section"]}>
                    <div className={styles["content-container"]}>
                        <div className={styles["text-section"]}>
                            <div>
                                <h2>About Localify</h2>
                                <h5 className={styles["subtitle"]}>
                                    Localify is a platform developed to help people discover and connect with their
                                    local music scenes, driven by a collaboration between Ithaca College and Cornell
                                    University. Funded by the NSF, it combines technology with a love for
                                    community-based
                                    music, showcasing artists and scenes often overlooked by mainstream platforms. Led
                                    by
                                    students and faculty, Localify bridges listeners to the heart of local music.
                                </h5>
                            </div>
                        </div>
                        {/* Video Section */}
                        <div className={styles["video-section"]}>
                            <iframe
                                src="https://www.youtube.com/embed/iwU7VrfeZLk?si=aNREY-ZqW0TwP_OU"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>
                <div className={styles["about-team"]}>
                    <h2 className={styles["title"]}>About the Team</h2>
                    <h5 className={styles["subtitle"]}>
                        Funded by the NSF, Localify is a collaboration between Ithaca College
                        and Cornell University, inspired by Ithaca's vibrant local music scene.
                        It helps people worldwide discover and enjoy their local music scenes.
                        Led by Ithaca College undergraduates and supervised by Professor Doug
                        Turnbull, with Cornell's support. Read on to meet our team, learn about
                        their work, and discover their favorite local artists!
                    </h5>
                    {teamMembers.subjects.map((subject, index) => (
                        <>
                            <h3
                                className={styles["subject-title"]}
                                key={index}
                            >{subject.name}</h3>
                            <div className={styles["team-grid"]}>
                                {subject.members.map((teamMember, index) => (
                                    <div
                                        key={index}
                                        className={styles["member-card"]}
                                    >
                                        <img
                                            alt={"Image of " + teamMember.name}
                                            src={teamMember.name === "Lydia Smith" ? lydiaImage : teamMember.image}
                                            className={styles["member-image"]}
                                        />
                                        <div className={styles["member-info"]}>
                                            <div className={styles["basic-info"]}>
                                                <h5 className={styles["member-name"]}>{teamMember.name}</h5>
                                                <h6 className={styles["grad-year"]}>{teamMember.yearOfGraduation}</h6>
                                            </div>
                                            <h6 className={styles["member-role"]}>{teamMember.role}</h6>
                                            <span
                                                onClick={() =>
                                                    navigate(`/artist/${teamMember.favoriteLocalBandId}/${toSlug(teamMember.favoriteLocalBand)}`)
                                                }
                                                className={styles["favorite-band"]}
                                            >
                                                <h6>{teamMember.favoriteLocalBand} - {teamMember.favoriteBandOrigin}</h6>
                                            </span>
                                            <p className={styles["bio"]}>{teamMember.bio}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
