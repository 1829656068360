import React, { useEffect, useState } from 'react';
import * as apiService from "../backend/apiService";
import { FavoriteTypes, ArtistResponse, EventResponse } from "../backend/apiService";
import {useNavigate, useParams} from "react-router-dom";
import styles from '../style/pages/Favorites.module.scss';
import BookmarkButton from "../components/BookmarkButton";
import * as stringFormatting from "../utils/StringFormatting";
import PageHeader, {HeaderTypes} from "../components/PageHeader";
import ListOfCitiesModal from "../components/ListOfCitiesModal";
import FeedbackFormButton from "../components/FeedbackFormButton";

const noImage = 'https://localify-cdn.s3.amazonaws.com/assets/web_assets/NoImage.jpeg';

const Favorites: React.FC = () => {
    const navigate = useNavigate();
    const {type} = useParams();
    const [favorites, setFavorites] = useState<(ArtistResponse | EventResponse)[]>([]);
    const [showEditCitiesModal, setShowEditCitiesModal] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (type === "artists") {
                    const artistDetails = await apiService.fetchFavorites(FavoriteTypes.artists);
                    setFavorites(artistDetails.content as ArtistResponse[]);
                } else if (type === "events") {
                    const eventDetails = await apiService.fetchFavorites(FavoriteTypes.events);
                    setFavorites(eventDetails.content as EventResponse[]);
                } else {
                    // TODO handle error
                }
            } catch {
                // TODO handle error
            }
        };

        fetchData().then();
    }, [type]);

    return (
        <div className={styles["main-container"]}>
            <PageHeader
                type={HeaderTypes.HOME}
            />
            <FeedbackFormButton />

            <h2 className={styles["title"]}>
                {type === "artists" ? "Your favorite artists" :
                    type === "events" ? "Your upcoming events" :
                        "Your bookmarked venues"}
            </h2>
            <div className={styles['favorites-container']}>
                {favorites.map((item, index) => {
                    if (type === "artists") {
                        const artist = item as ArtistResponse;
                        return (
                            <div
                                key={index}
                                className={styles["flex-item"]}
                                onClick={() => window.location.href = `/artist/${artist.id}/${stringFormatting.toSlug(artist.name)}`}
                            >
                                <img src={artist.image || noImage} alt={`Image ${index + 1}`}
                                     className={styles["image"]}/>
                                <BookmarkButton
                                    initialIsFavorite={true}
                                    type={FavoriteTypes.artists}
                                    id={artist.id}
                                    handleError={() => console.log("error")} //TODO set error
                                />
                                <h6 className={styles["text-under-image"]}>{artist.name}</h6>
                            </div>
                        );
                    } else if (type === "events") {
                        const event = item as EventResponse;
                        const dateCard = stringFormatting.formatDateCard(event.startTime);

                        return (
                            <div
                                key={index}
                                className={styles["flex-item"]}
                                onClick={() => window.location.href = `/event/${event.id}/${stringFormatting.toSlug(event.name)}`}
                            >
                                <img src={event?.topArtists[0]?.image || noImage} alt={`Image ${index + 1}`}
                                     className={styles["image"]}/>
                                <div className={styles["date-card"]}>
                                    <h6>{dateCard.day}</h6>
                                    <h4>{dateCard.month} {dateCard.dayOfMonth}</h4>
                                    <h6>{dateCard.time}</h6>
                                </div>
                                <BookmarkButton
                                    initialIsFavorite={true}
                                    type={FavoriteTypes.events}
                                    id={event.id}
                                    handleError={() => console.log("error")} //TODO set error
                                />
                                <h6 className={styles["text-under-image"]}>{event.name}</h6>
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
            {showEditCitiesModal &&
                <ListOfCitiesModal
                    onClose={() => {
                        setShowEditCitiesModal(false);
                    }}
                />
            }
        </div>
    );
};

export default Favorites;
