import React, {useEffect, useRef, useState} from 'react';
import {ArtistRecResponse, FavoriteTypes, GenreResponse} from '../backend/apiService';
import styles from "../style/components/RecommendationCard.module.scss";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import * as stringFormatting from "../utils/StringFormatting";
import Tooltip from '@mui/material/Tooltip';
import BookmarkButton from "./BookmarkButton";
import { PauseCircleOutline, PlayCircleOutline } from "@mui/icons-material";

type Props = {
    artist: ArtistRecResponse;
    handleError: () => void;
    onPlay: (audio: HTMLAudioElement) => void;
}

const ArtistRecommendationCard: React.FC<Props> = ({ artist, handleError, onPlay }) => {
    const navigate = useNavigate();

    const audioRef = useRef<HTMLAudioElement>(null);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);

    const noImage = 'https://localify-cdn.s3.amazonaws.com/assets/web_assets/NoImage.jpeg';

    const toggleAudio = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
                onPlay(audioRef.current);
            }
            setIsPlaying(!isPlaying);
        }
    };

    useEffect(() => {
        const audioElement = audioRef.current;

        const handlePause = () => setIsPlaying(false);

        if (audioElement) {
            audioElement.addEventListener('pause', handlePause);
        }

        return () => {
            if (audioElement) {
                audioElement.removeEventListener('pause', handlePause);
            }
        };
    }, []);

    function formatGenres(genres: GenreResponse[]): string {
        return genres.map(genre => `#${genre.name}`).join(' ');
    }
    let genres = formatGenres(artist.genres);

    return (
        <div className={styles["card-container"]}>
            <img alt={""} src={artist.image ? artist.image : noImage} className={styles["image"]} />
            <div className={styles["description"]}>
                <div>
                    <h5>{artist.name}</h5>
                    <h6>{genres || `\u00A0`}</h6> {/* genres string or empty space if genres is null */}
                    <div
                        onClick={(event) => event.stopPropagation()} /* makes this div un-clickable for aesthetic reasons */
                        className={styles["default-cursor"]}
                    >
                        <h6 className={styles["similar-text"]}>Similar to:</h6>
                        <div className={styles["similar-artist-container"]}>
                            {artist.similar.slice(0, 3).map((similarArtist, index) => {
                                return (
                                    <Tooltip title={similarArtist.name} key={index} placement="bottom" arrow>
                                        <img
                                            alt=""
                                            src={similarArtist.image ? similarArtist.image : noImage}
                                            onClick={(event) => {
                                                event.stopPropagation(); // Prevents the click event from triggering the parent 'onClick'
                                                navigate(`/artist/${similarArtist.id}/${stringFormatting.toSlug(similarArtist.name)}`);
                                            }}
                                        />
                                    </Tooltip>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className={styles["end-container"]}>
                    {artist.spotifyTopTrackPreview ? (
                        <div>
                            <audio ref={audioRef}>
                                <source //"https://p.scdn.co/mp3-preview/ca7bdcb691fb64a5af8a3253b89356390664fcf1?cid=3fc5f299651443559142458908c467f8"
                                    src={artist.spotifyTopTrackPreview}
                                    type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                            <div className={styles['play-button-container']} onClick={(e) => {
                                e.stopPropagation();
                                toggleAudio();
                            }}>
                                {isPlaying ? (
                                    <PauseCircleOutline className={styles['icon']} />
                                ) : (
                                    <PlayCircleOutline className={styles['icon']} />
                                )}
                            </div>
                        </div>
                    ) : (
                        <Tooltip title={"Audio Unavailable"} placement="top" arrow>
                            <div
                                className={`${styles['play-button-container']} ${styles['disabled']}`}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <PlayCircleOutline className={styles['icon']}/>
                            </div>
                        </Tooltip>
                    )}
                    <div
                        title={`${Math.round(artist.score * 100)}% Match`}
                        className={styles["default-cursor"]}
                        onClick={(event) => event.stopPropagation()}
                    >
                        <h6 className={styles["text-center"]}>% Match</h6>
                        <Box sx={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                        }}>
                            <CircularProgress variant="determinate" value={artist.score * 100} style={{
                                color: "#E84865",
                                width: '75%',
                                height: '75%',
                            }} />
                            <Box
                                sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%', // Full width
                                    height: '100%', // Full height
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    component="div"
                                    className={styles["white-text"]}
                                >{`${Math.round(artist.score * 100)}`}</Typography>
                            </Box>
                        </Box>
                    </div>
                </div>
            </div>
            <BookmarkButton
                initialIsFavorite={artist.isFavorite}
                type={FavoriteTypes.artists}
                id={artist.id}
                handleError={handleError}
            />
        </div>
    );
};

export default ArtistRecommendationCard;
