import React, { useState, useEffect } from 'react';
import "../style/components/components.scss";
import PlaceIcon from '@mui/icons-material/Place';
import midArtistsJSON from '../utils/midArtists.json';
import {useNavigate} from "react-router-dom";
import * as stringFormatting from "../utils/StringFormatting";

interface Artist {
    artist_id: string;
    artist_name: string;
    spotify_popularity: number;
    image: string;
    city_id: string;
    city_name: string;
    zone_code: string;
    country_code: string;
}

// Function to shuffle an array
function shuffleArray(array: Artist[]): Artist[] {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

// Shuffle the JSON data and pick the first 12 unique artists
// @ts-ignore
const shuffledArtists = shuffleArray(midArtistsJSON);
const midArtists: Artist[] = shuffledArtists.slice(0, 12);

const LandingPageWidget: React.FC = () => {
    const navigate = useNavigate();
    const [currentArtistIndex, setCurrentArtistIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentArtistIndex(prevIndex => (prevIndex + 1) % midArtists.length);
        }, 2000);

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    return (
        <div className={'artist-gallery'}>
            <div
                className={'focused-artist'}
                onClick={() => navigate(`/artist/${midArtists[currentArtistIndex].artist_id}/${stringFormatting.toSlug(midArtists[currentArtistIndex].artist_name)}`)}
            >
                <img alt={midArtists[currentArtistIndex].artist_name} src={midArtists[currentArtistIndex].image}/>
                <p className={'artist-name'}>{midArtists[currentArtistIndex].artist_name}</p>
                <p className={'artist-location'}>
                    <PlaceIcon/>{`${midArtists[currentArtistIndex].city_name}, ${midArtists[currentArtistIndex].zone_code}`}
                </p>
            </div>
            <div
                className={'unfocused-artist'}
                onClick={() => navigate(`/artist/${midArtists[(currentArtistIndex + 1) % midArtists.length].artist_id}/${stringFormatting.toSlug(midArtists[(currentArtistIndex + 1) % midArtists.length].artist_name)}`)}
            >
                <img alt={midArtists[(currentArtistIndex + 1) % midArtists.length].artist_name} src={midArtists[(currentArtistIndex + 1) % midArtists.length].image}/>
                <p className={'artist-name'}>{midArtists[(currentArtistIndex + 1) % midArtists.length].artist_name}</p>
                <p className={'artist-location'}>
                    {`${midArtists[(currentArtistIndex + 1) % midArtists.length].city_name}, ${midArtists[(currentArtistIndex + 1) % midArtists.length].zone_code}`}
                </p>
            </div>
            <div
                className={'unfocused-artist'}
                onClick={() => navigate(`/artist/${midArtists[(currentArtistIndex + 2) % midArtists.length].artist_id}/${stringFormatting.toSlug(midArtists[(currentArtistIndex + 2) % midArtists.length].artist_name)}`)}
            >
                <img alt={midArtists[(currentArtistIndex + 2) % midArtists.length].artist_name}
                     src={midArtists[(currentArtistIndex + 2) % midArtists.length].image}/>
                <p className={'artist-name'}>{midArtists[(currentArtistIndex + 2) % midArtists.length].artist_name}</p>
                <p className={'artist-location'}>
                    {`${midArtists[(currentArtistIndex + 2) % midArtists.length].city_name}, ${midArtists[(currentArtistIndex + 2) % midArtists.length].zone_code}`}
                </p>
            </div>
        </div>
    );
};

export default LandingPageWidget;
