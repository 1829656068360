import React, {useEffect} from 'react';
import * as Store from "../backend/storage";
import * as loginApi from "../backend/loginApi"
import {LoginStrategy} from "../backend/loginApi"
import {useNavigate} from 'react-router-dom';
import handleLogin from "../utils/handleLogin";
import "../style/App.scss"
import ActivityIndicator from "../components/ActivityIndicator";

const SpotifyRedirect = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const spotifyRedirectHandler = async () => {
            const queryParams = new URLSearchParams(window.location.search);
            const code = queryParams.get("code");
            const secret = await Store.getSpotifySecret();

            if (code == undefined)
                throw new Error("Code is undefined")
            else
                loginApi.createSpotifyAccount(secret, code).then((tokenData) => {
                    Store.storeTokenData(tokenData).then(async () => {
                        Store.storeUserLoginStrategy(LoginStrategy.SPOTIFY);
                        const nextPage = await handleLogin();
                        navigate(nextPage);
                    })
                }).catch((error) => {
                    console.log("Error creating Spotify account: " + error);
                });
        }
        spotifyRedirectHandler().then(r => r);
    }, [])

    return (
        <div className={"center-loading-button"}>
            <center><ActivityIndicator/><p>Connecting to Spotify...</p></center>
        </div>
    )
}

export default SpotifyRedirect
