import React, {useState} from "react";
import styles from "../style/pages/Settings.module.scss";
import globalColors from "../style/globalColors";
import ActivityIndicator from "./ActivityIndicator";
import * as apiService from "../backend/apiService";

type Props = {
    onClose: () => void;
    onError: () => void;
    onSubmit: (emailText: string, emailNonce: string) => void;
}

const EmailEnterModal: React.FC<Props> = ({
    onClose,
    onError,
    onSubmit
}) => {

    const [showEmailWarning, setShowEmailWarning] = useState<boolean>(false);
    const [emailButtonActive, setEmailButtonActive] = useState<boolean>(false);
    const [emailText, setEmailText] = useState<string>("");

    const submitEmailConnect = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(emailText)) {
            setShowEmailWarning(true);
        } else {
            setShowEmailWarning(false);
            setEmailButtonActive(true);
            apiService.emailVerification(emailText).then((resp) => {
                setShowEmailWarning(false);
                onSubmit(emailText, resp.nonce);
            }).catch(error => {
                onError();
            });
        }
    }

    return (
        <div
            style={{
                position: "fixed", // Fixed position for modal
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000, // Adjust the z-index as needed
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
            }}
        >
            <div
                style={{
                    width: "30%", // You can adjust the width as needed
                    backgroundColor: globalColors.dark,
                    padding: "20px", // Add padding for content
                    borderRadius: "10px",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)", // Optional shadow
                    color: globalColors.white,
                    justifyItems: "center",
                    alignItems: "center"
                }}

            >
                <div>
                    <h4
                        style={{
                            height: 50
                        }}
                    >
                        Enter Your Email:
                    </h4>
                    <input
                        style={{
                            width: "96%",
                            height: 50,
                            borderRadius: 8,
                            borderColor: globalColors.white,
                            borderWidth: "2px",
                            backgroundColor: "rgba(0, 0, 0, 0)",
                            paddingLeft: 10,
                            color: globalColors.white,
                            fontSize: 16
                        }}
                        placeholder={"Email Address"}
                        value={emailText}
                        onChange={(event) => setEmailText(event.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter")
                                submitEmailConnect();
                            if (e.key === "Escape")
                                onClose();
                        }}
                    />
                    <div className={styles['buttons']}>
                        <h6 onClick={onClose}>Cancel</h6>
                        <h6 onClick={submitEmailConnect}>
                            {!emailButtonActive ? `Continue` : (
                                <div style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                }}>
                                    <ActivityIndicator color="white" width={"20px"} height={"20px"}/>
                                </div>
                            )}
                        </h6>
                    </div>
                    {showEmailWarning &&
                        <h6 style={{fontSize: 12, marginTop: 8, marginBottom: 4, color: globalColors.primary}}>
                            Please enter a valid email address
                        </h6>
                    }
                </div>
            </div>
        </div>
    )
}

export default EmailEnterModal;
