import React, {useEffect, useState} from 'react';
import styles from '../style/components/OptInToggleSwitch.module.scss';

type ToggleSwitchProps = {
    onToggle: (isOn: boolean) => void;
    initialState: boolean;
};

const OptInToggleSwitch: React.FC<ToggleSwitchProps> = ({ onToggle, initialState }) => {
    const [isOn, setIsOn] = useState(initialState);

    useEffect(() => {
        setIsOn(initialState);
    }, [initialState]);

    const handleToggle = () => {
        setIsOn(!isOn);
        onToggle(!isOn);
    };

    return (
        <div
            className={`${styles.toggleSwitch} ${isOn ? styles.toggleSwitchOn : ''}`}
            onClick={handleToggle}
        >
            <div
                className={`${styles.toggleKnob} ${isOn ? styles.toggleKnobOn : styles.toggleKnobOff}`}
            />
        </div>
    );
};

export default OptInToggleSwitch;