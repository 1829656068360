

export const formatEventDate = (dateString: number) => {
    const options: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
        timeZone: 'America/New_York'
    };
    return new Date(dateString).toLocaleDateString('en-US', options);
};

export const formatMonthDayYear = (dateString: number): string => {
    const date = new Date(dateString);
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];

    const day = String(date.getDate()).padStart(2, '0');
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
};

export const formatDateCard = (timestamp: number): {day: string, month: string, dayOfMonth: number, time: string} => {
    const date = new Date(timestamp);
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const monthsOfYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const day = daysOfWeek[date.getDay()];
    const month = monthsOfYear[date.getMonth()];
    const dayOfMonth = date.getDate();

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const time = `${formattedHours}:${formattedMinutes} ${ampm}`;

    return { day, month, dayOfMonth, time };
}

export const toSlug = (name: string) => {
    return name
        .toLowerCase() // Convert the string to lowercase
        .trim() // Remove whitespace from both ends of the string
        .replace(/[^a-z0-9 -]/g, '') // Remove all characters that are not alphanumeric, space, or hyphen
        .replace(/\s+/g, '-') // Replace spaces with hyphens
        .replace(/-+/g, '-'); // Replace multiple hyphens with a single hyphen
};

export const toLocationLabel = (name: string, zoneCode: string, countryCode: string) => {
    if (zoneCode != null) return `${name}, ${zoneCode}`;
    if (countryCode != null) return `${name}, ${countryCode}`;
    return name;
}
